import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import {
  Box,
  TextField,
  useTheme,
  Button,
  Typography,
  useMediaQuery,
  Autocomplete,
  Paper,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import useAxiosPrivate from "../../api/useAxiosPrivate";

const EditBankInformation = (props) => {
  const {
    recipient,
    handleClose,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
    bankInformationInfo,
  } = props;
  const [bankName, setBankName] = useState(bankInformationInfo.bankName);
  const [bankBranch, setBankBranch] = useState(bankInformationInfo.bankBranch);
  const [bankAccount, setBankAccount] = useState(
    bankInformationInfo.bankAccount
  );
  const [pixKeyType, setPixKeyType] = useState(bankInformationInfo.pixKeyType);
  const [pixKey, setPixKey] = useState(bankInformationInfo.pixKey);
  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const pixKeyTypes = ["CPF", "Celular", "Email", "Chave Aleatória"];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosPrivate.put(
        `/bankInformation/${bankInformationInfo.id}`,
        JSON.stringify({
          bankName,
          bankBranch,
          bankAccount,
          pixKeyType,
          pixKey,
        }),
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setAlertMessage("Informações Alteradas");
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (error) {
      setAlertMessage("Tivemos um problema para salvar as alterações");
      setSeverity("error");
      setOpenAlert(true);
    }
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  return (
    <Box>
      <Header
        title={`INFORMAÇÕES BANCÁRIAS`}
        subtitle={`${recipient.name}`}
        button={false}
        color="#E35A01"
      />
      <Box
        borderRadius="10px"
        padding="10px"
        marginTop="20px"
        marginBottom="20px"
        backgroundColor={colors.primary[400]}
        display="grid"
        gap="5px"
        sx={{
          "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
          gridTemplateColumns: "repeat(4, 1fr)",
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 4" }}
        >
          Novas Informações Bancárias
        </Typography>
        <TextField
          size="small"
          fullWidth
          value={bankName}
          variant="outlined"
          type="text"
          label="Nome do Banco"
          sx={{
            backgroundColor: colors.grey[900],
            gridColumn: isNonMobile ? "span 2" : "span 4",
          }}
          onChange={(e) => setBankName(e.target.value)}
          //inputProps={{ readOnly: true }}
        />
        <TextField
          size="small"
          fullWidth
          value={bankBranch}
          variant="outlined"
          type="text"
          label="Agência"
          sx={{
            backgroundColor: colors.grey[900],
            gridColumn: isNonMobile ? "span 1" : "span 2",
          }}
          onChange={(e) => setBankBranch(e.target.value)}
        />
        <TextField
          size="small"
          fullWidth
          value={bankAccount}
          variant="outlined"
          type="text"
          label="Conta"
          sx={{
            backgroundColor: colors.grey[900],
            gridColumn: isNonMobile ? "span 1" : "span 2",
          }}
          onChange={(e) => setBankAccount(e.target.value)}
          //inputProps={{ readOnly: true }}
        />
        <Autocomplete
          fullWidth
          size="small"
          disableClearable
          value={pixKeyType}
          variant="outlined"
          id="pix-type"
          options={pixKeyTypes}
          sx={{
            gridColumn: isNonMobile ? "span 1" : "span 4",
            backgroundColor: colors.grey[900],
          }}
          onChange={(_, newValue) => setPixKeyType(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Tipo de Chave Pix" />
          )}
        />
        <TextField
          size="small"
          fullWidth
          value={pixKey}
          variant="outlined"
          type="text"
          label="Chave Pix"
          sx={{
            backgroundColor: colors.grey[900],
            gridColumn: isNonMobile ? "span 3" : "span 4",
          }}
          onChange={(e) => setPixKey(e.target.value)}
        />
        <Box sx={{ gridColumn: "span 4" }} />
      </Box>
      <Box
        borderRadius="10px"
        padding="10px"
        marginTop="20px"
        marginBottom="20px"
        backgroundColor={colors.primary[400]}
        display="grid"
        gap="5px"
        sx={{
          "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
          gridTemplateColumns: "repeat(4, 1fr)",
        }}
      >
        <Box sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }} />
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          height="100%"
          onClick={handleSubmit}
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
        >
          <Typography fontWeight="bold">Alterar Informações</Typography>
        </Button>
        <ColorButton
          variant="contained"
          height="100%"
          onClick={handleClose}
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
        >
          FECHAR
        </ColorButton>
      </Box>
    </Box>
  );
};

export default EditBankInformation;
