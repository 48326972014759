import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import NewClient from '../scenes/Clients/newClient';

export default function ClientDialog(props) {

  const { openNewClientDialog, handleClose, setAlertMessage, setSeverity, setOpenAlert} = props

  return (

    
    <div>
      <Dialog open={openNewClientDialog} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>
          <NewClient handleClose={handleClose} setAlertMessage={setAlertMessage} setSeverity={setSeverity} setOpenAlert={setOpenAlert}/>
        </DialogContent>
      </Dialog>
    </div>
  );
}