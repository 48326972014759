import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import NewStoreProvider from "../scenes/settings/providers/newStoreProvider";

export default function StoreProviderDialog(props) {
  const {
    openNewStoreProviderDialog,
    handleClose,
    providers,
    stores,
    storeProviders,
    setAlertMessage,
    setSeverity,
    setOpenAlert,
  } = props;

  return (
    <div>
      <Dialog
        open={openNewStoreProviderDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <NewStoreProvider
            handleClose={handleClose}
            providers={providers}
            stores={stores}
            storeProviders={storeProviders}
            setAlertMessage={setAlertMessage}
            setSeverity={setSeverity}
            setOpenAlert={setOpenAlert}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
