import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DeleteRecipient from "../scenes/Recipients/deleteRecipient";

export default function DeleteRecipientDialog(props) {
  const {
    openDeleteRecipientDialog,
    recipientInfo,
    handleClose,
    clients,
    setAlertMessage,
    setSeverity,
    setOpenAlert,
  } = props;

  return (
    <div>
      <Dialog
        open={openDeleteRecipientDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <DeleteRecipient
            handleClose={handleClose}
            clients={clients}
            recipientInfo={recipientInfo}
            setAlertMessage={setAlertMessage}
            setSeverity={setSeverity}
            setOpenAlert={setOpenAlert}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
