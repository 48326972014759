import { Typography, Box, Paper } from "@mui/material";


const Header = ({title, subtitle, color='#0c1c3c'}) => {
    return (
        <>
        <Paper elevation={3} width={"80%"} sx={{"background": color}}>
        <Box display='flex' flexDirection="column" margin="5px" p="5px" justifyContent="space-between" elevation={6} spacing={2} width={"100%"}>
            <Typography
             variant="h1"
             color = "white"
             fontWeight="bold"
            >
                {title}
            </Typography>

            <Typography
            gutterBottom
            variant="h5"
            color = "white"
            fontWeight="bold">{subtitle}</Typography>
        </Box>
      
    </Paper>
    <Box height="4px"/>
    </>
    );
}

export default Header;