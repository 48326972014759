import {
  Box,
  Button,
  LinearProgress,
  useMediaQuery,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CustomNoRowsOverlay from "../../../components/NoRowsOverlay";
import useAxiosPrivate from "../../../api/useAxiosPrivate";

import { tokens } from "../../../theme";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { useTheme } from "@mui/material";
import NewUserDialog from "../../../components/NewUserDialog";
import DeleteUserDialog from "../../../components/DeleteUserDialog";

import { useState, useEffect } from "react";
import Toast from "../../../components/Snackbar";
import { styled } from "@mui/material/styles";

const UsersSettings = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const [users, setUsers] = useState([]);
  const [user_info, setUserInfo] = useState({});

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [loadingData, setLoadingData] = useState(true);
  const [openNewUserDialog, setOpenNewUserDialog] = useState(false);
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const USERS_URL = "/users/storeChain";

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get(USERS_URL);
        setUsers(response.data.filter((user) => user.role === "EMPLOYEE"));
        setLoadingData(false);
      } catch (err) {}
    };

    getUsers();
  }, [openNewUserDialog, openDeleteUserDialog]);

  const newUser = () => {
    setOpenNewUserDialog(true);
  };

  const handleClose = () => {
    setOpenNewUserDialog(false);
    setOpenDeleteUserDialog(false);
  };

  const handleDeleteClick = (id) => () => {
    const info = users.filter((user) => user.id === id);
    setUserInfo(info[0]);
    setOpenDeleteUserDialog(true);
  };

  const columns = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Telefone",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Opções",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#621B74",
    "&:hover": {
      backgroundColor: "#814990",
    },
  }));

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 1,
          display: "flex",
          gap: "10px",
        }}
      >
        <GridToolbarQuickFilter
          placeholder="Busque por Nome, E-mail ou Telefone de Usuário"
          variant="outlined"
          style={{ flex: 0.5 }}
        />
        <ColorButton
          variant="contained"
          onClick={newUser}
          sx={{ gridColumn: "span 2" }}
        >
          <AddCircleOutlinedIcon sx={{ mr: "10px" }} />
          <Typography fontWeight="bold">NOVO</Typography>
        </ColorButton>
      </Box>
    );
  }

  return (
    <Box>
      <Box
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: "#0c1c3c",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#621B74",
            borderBottom: "none",
            color: "white",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.grey[100],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "#fff",
          },
        }}
      >
        <DataGrid
          loading={loadingData}
          getRowId={(row) => row.id}
          rows={users}
          columns={columns}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{
            toolbar: QuickSearchToolbar,
            loadingOverlay: LinearProgress,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Box>
      <Toast
        open={openAlert}
        alertMessage={alertMessage}
        setOpen={setOpenAlert}
        severity={severity}
      />
      <NewUserDialog
        openNewUserDialog={openNewUserDialog}
        handleClose={handleClose}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />

      <DeleteUserDialog
        openDeleteStoreDialog={openDeleteUserDialog}
        handleClose={handleClose}
        user_info={user_info}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
    </Box>
  );
};

export default UsersSettings;
