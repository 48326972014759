import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../theme";
import { Typography, useTheme } from "@mui/material";
//import CenteredMetric from "@nivo";

const PieChartReport = (props) => {
  const { data, dolar, CustomLayerComponent } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let USDollar = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return (
    <ResponsivePie
      data={data}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
      }}
      legends={[]}
      margin={{ top: 20, right: 20, bottom: 60, left: 20 }}
      id="clientType"
      //colors={datum.}
      colors={{ datum: "data.color" }}
      //valueFormat={}
      animate={true}
      innerRadius={0.7}
      padAngle={0.7}
      cornerRadius={2}
      activeOuterRadiusOffset={6}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLabel={(e) => (dolar ? USDollar.format(e.value) : e.value)}
      arcLinkLabelsStraightLength={10}
      arcLinkLabelsDiagonaltLength={10}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      enableArcLabels={true}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      layers={[
        "arcs",
        "arcLabels",
        "arcLinkLabels",
        "legends",
        CustomLayerComponent,
      ]}
      tooltip={({ datum: { id, value, color, data } }) => (
        <div
          style={{
            padding: 12,
            background: "white",
          }}
        >
          <strong>
            {data.label}
            <br />
            {dolar && `USD ${USDollar.format(value)}`}
            {!dolar && value}
            <br />
            {Math.round(data.proportion * 100).toFixed(0)}%
          </strong>
        </div>
      )}
    />
  );
};

export default PieChartReport;
