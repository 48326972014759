import {
  Box,
  useTheme,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { tokens } from "../theme";
import Header from "../components/Header";
import useAxiosPrivate from "../api/useAxiosPrivate";
import { useTranslation } from "react-i18next";
import { red } from "@mui/material/colors";
import { useState } from "react";
import dayjs from "dayjs";
const DELETE_FILE_URL = "/clients/documents/";

const DeleteFile = (props) => {
  const { handleClose, setAlertMessage, setOpenAlert, setSeverity, fileInfo } =
    props;
  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [deletingFile, setDeletingFile] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    setDeletingFile(true);

    try {
      const response = await axiosPrivate.delete(
        `${DELETE_FILE_URL}${fileInfo.id}`,
        {
          headers: { "Content-Type": "multipart/form-data" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 204) {
        setAlertMessage(t("files.deleteFile.successAlertMessage"));
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(t("files.deleteFile.serverFailAlertMessage"));
        setSeverity("error");
        setOpenAlert(true);
        setDeletingFile(false);
      } else if (err.response?.status === 400) {
        setAlertMessage(
          err.response?.data.message ||
            t("files.deleteFile.serverFailAlertMessage")
        );
        setSeverity("error");
        setOpenAlert(true);
        setDeletingFile(false);
      } else {
        setAlertMessage(t("files.deleteFile.serverFailAlertMessage"));
        setSeverity("error");
        setOpenAlert(true);
        setDeletingFile(false);
      }
    }
  };

  return (
    <Box>
      <Box>
        <Header
          title={t("files.deleteFile.header.title")}
          subtitle={t("files.deleteFile.header.subtitle")}
          button={false}
          color="#f04646"
        />
        <Box
          sx={{
            borderRadius: 3,
            autoComplete: "off",
            padding: 3,
            gap: 2,
            bgcolor: colors.grey[100],
            display: "grid",
            gridTemplateColumns: "repeat(8, 1fr)",
          }}
        >
          <Typography sx={{ gridColumn: "span 8" }}>
            {t("files.deleteFile.bodyText1")}
          </Typography>
          <Box sx={{ gridColumn: "span 8" }}>
            <p>
              {t("files.deleteFile.fileName")}: {fileInfo.fileName}
            </p>
            <p>
              {t("files.deleteFile.fileType")}: {fileInfo.documentType}
            </p>
            {fileInfo.expirationDate ? (
              <p>
                {t("files.deleteFile.expirationDate")}:{" "}
                {dayjs(fileInfo.expirationDate).format("YYYY-MM-DD")}
              </p>
            ) : (
              <>x</>
            )}
          </Box>
          <Typography sx={{ gridColumn: "span 8" }}>
            {t("files.deleteFile.bodyText2")}
          </Typography>

          <Divider variant="middle" sx={{ gridColumn: "span 8" }} />

          {isNonMobile && <Box sx={{ gridColumn: "span 4" }} />}

          <Button
            disabled={deletingFile}
            type="submit"
            color="secondary"
            variant="contained"
            height="100%"
            onClick={handleSubmit}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          >
            <Typography fontWeight="bold">
              {t("files.deleteFile.confirmButton")}
            </Typography>
          </Button>
          <Button
            disabled={deletingFile}
            sx={{
              backgroundColor: red[700],
              color: colors.grey[100],
              "&:hover": {
                backgroundColor: red[600],
                color: "white",
              },
              gridColumn: isNonMobile ? "span 2" : "span 4",
            }}
            variant="contained"
            height="100%"
            onClick={handleClose}
          >
            <Typography fontWeight="bold">
              {t("files.deleteFile.cancelButton")}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default DeleteFile;
