import {
  Box,
  useTheme,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useState, useEffect, useCallback, useContext } from "react";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/NoRowsOverlay";
import NewTransactionDialog from "../../components/NewTransactionDialog";
import EditTransactionDialog from "../../components/EditTransactionDialog";
import DeleteTransactionDialog from "../../components/DeleteTransactionDialog";
import TransactionMiniReportDialog from "../../components/TransactionMiniReportDialog";
import UploadLedgerDialog from "../../components/UploadLedgerDialog";
import { DataGrid, GridToolbarQuickFilter } from "@mui/x-data-grid";
import dayjs from "dayjs";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import Toast from "../../components/Snackbar";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { renderStatus } from "./cell-rendererStyledChip";
import { storeContext } from "../../context/storeContext";
import SelectStore from "../../components/SelectStore";

const QuickSearchToolbar = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        p: 0.5,
        display: "flex",
        gap: "4px",

        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <GridToolbarQuickFilter
        size="small"
        placeholder={t("transactions.table.quickSearchPlaceHolder")}
        variant="outlined"
        style={{ flex: 0.5 }}
      />
    </Box>
  );
};

const Transactions = () => {
  const axiosPrivate = useAxiosPrivate();
  const { t } = useTranslation();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  let USDollar = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [storesList, setStoresList] = useContext(storeContext);

  const [transactions, setTransactions] = useState([]);
  const [storeProviders, setStoreProviders] = useState([]);
  const [stores, setStores] = useState([]);
  const [clients, setClients] = useState([]);
  const [recipients, setRecipients] = useState([]);
  const [transactionInfo, setTransactionInfo] = useState({});

  const [openNewTransactionDialog, setOpenNewTransactionDialog] =
    useState(false);
  const [openEditTransactionDialog, setOpenEditTransactionDialog] =
    useState(false);
  const [openDeleteTransactionDialog, setOpenDeleteTransactionDialog] =
    useState(false);
  const [openTransactionMiniReportDialog, setOpenTransactionMiniReportDialog] =
    useState(false);
  const [openUploadLedgerDialog, setOpenUploadLedgerdialog] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [forceLoadClients, setForceLoadClients] = useState(false);

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
  });

  const [queryOptions, setQueryOptions] = useState({
    filterModel: {
      items: [],
      logicOperator: "and",
      quickFilterValues: [],
      quickFilterLogicalOperator: "and",
    },
  });

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  useEffect(() => {
    const params = {
      page: pageState.page,
      filter: !!queryOptions.filterModel.quickFilterValues.length
        ? `${queryOptions.filterModel.quickFilterValues.join(" ")}/`
        : "",
      storeId: !!storesList.length ? `${storesList.map(({ id }) => id)}` : "",
    };

    const getTransactions = async () => {
      try {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }));
        const response = await axiosPrivate.get(
          `/transactions/paginated/page/${params.page}/filter/${params.filter}storeId/${params.storeId}`
        );
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: response.data.rows,
          total: response.data.count,
        }));
      } catch (err) {}
    };

    getTransactions();
  }, [
    storesList,
    pageState.page,
    queryOptions.filterModel,
    openNewTransactionDialog,
    openEditTransactionDialog,
    openDeleteTransactionDialog,
  ]);
  /* 
  useEffect(() => {
    const getTransactionsToday = async () => {
      try {
        const response = await axiosPrivate.get(
          `/transactions/storeChainToday`
        );
        barDataProcessing(response.data, setBarDataAmount, setBarDataQuantity);
      } catch (err) {}
    };

    getTransactionsToday();
  }, [
    openNewTransactionDialog,
    openEditTransactionDialog,
    openDeleteTransactionDialog,
  ]);
  
    
  useEffect(() => {
    setBarData(barDataAmount);
    setBarDataType("Amount");
  }, [barDataAmount, barDataQuantity]);
  

  const selectAmountBar = () => {
    setBarData(barDataAmount);
    setBarDataType("Amount");
  };

  const selectQuantityBar = () => {
    setBarData(barDataQuantity);
    setBarDataType("Quantity");
  };
  */

  useEffect(() => {
    const getStoreProviders = async () => {
      try {
        const response = await axiosPrivate.get("/storeProvider/storeChain");
        setStoreProviders(response.data);
      } catch (err) {}
    };
    getStoreProviders();
  }, []);

  useEffect(() => {
    const getStores = async () => {
      try {
        const response = await axiosPrivate.get("/stores");
        setStores(response.data);
      } catch (err) {}
    };

    getStores();
  }, []);

  useEffect(() => {
    const getClients = async () => {
      try {
        const response = await axiosPrivate.get("/clients");
        setClients(response.data);
      } catch (err) {}
    };

    getClients();
  }, [forceLoadClients]);

  useEffect(() => {
    const getRecipients = async () => {
      try {
        const response = await axiosPrivate.get("/recipients");
        setRecipients(response.data);
      } catch (err) {}
    };

    getRecipients();
  }, [forceLoadClients]);

  const getTransactionInfo = async (id) => {
    try {
      const response = await axiosPrivate.get(`/transactions/${id}`);
      setTransactionInfo(response.data);
    } catch (err) {}
  };

  const newTransaction = () => {
    setOpenNewTransactionDialog(true);
  };
  const uploadLedger = () => {
    setOpenUploadLedgerdialog(true);
  };

  const handleClose = () => {
    setOpenNewTransactionDialog(false);
    setOpenEditTransactionDialog(false);
    setOpenDeleteTransactionDialog(false);
    setOpenUploadLedgerdialog(false);
  };

  const handleRowClick = async (e) => {
    await getTransactionInfo(e.id);
    setOpenEditTransactionDialog(true);
  };

  const handleEditClick = (id) => () => {
    setTransactionInfo(
      pageState.data.find((transaction) => transaction.id === id)
    );
    setOpenEditTransactionDialog(true);
  };

  const handleDeleteClick = (id) => () => {
    setTransactionInfo(
      pageState.data.find((transaction) => transaction.id === id)
    );
    setOpenDeleteTransactionDialog(true);
  };

  const handleCloseTransactionMiniReportDialog = () => {
    setOpenTransactionMiniReportDialog(false);
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#9f1853",
    "&:hover": {
      backgroundColor: "#d9528d",
    },
  }));

  const columns = [
    {
      field: "client",
      headerName: t("transactions.table.columns.client"),
      flex: 1,
      valueGetter: (params) => {
        return params.row.client ? params.row.client.name : "Desconhecido";
      },
    },
    {
      field: "recipient",
      headerName: t("transactions.table.columns.recipient"),
      flex: 1,
      valueGetter: (params) => {
        return params.row.recipient ? params.row.recipient.name : "";
      },
    },

    {
      field: "amountCollected",
      headerName: t("transactions.table.columns.amount"),
      flex: 0.5,
      type: "number",
      valueFormatter: (params) => {
        return USDollar.format(params.value);
      },
      getApplyQuickFilterFn: undefined,
    },
    {
      field: "dateTransaction",
      headerName: t("transactions.table.columns.dateTransaction"),
      flex: 1,
      valueGetter: (params) => {
        return dayjs(params.row.dateTransaction).format("YYYY-MM-DD");
      },
      renderCell: renderStatus,
      getApplyQuickFilterFn: undefined,
    },

    {
      field: "provider",
      headerName: t("transactions.table.columns.provider"),
      flex: 0.5,
      valueGetter: (params) => {
        return params.row.storeProvider
          ? params.row.storeProvider.Provider.name
          : "";
      },
    },
    {
      field: "store",
      headerName: t("transactions.table.columns.store"),
      valueGetter: (params) => {
        return params.row.store ? params.row.store.name : "Desconhecido";
      },
    },
  ];

  return (
    <Box m="8px">
      <Header
        title={t("transactions.title")}
        subtitle={t("transactions.subtitle")}
        color="#9f1853"
      />
      <Box display="grid" gridTemplateColumns="repeat(10, 1fr)" gap="4px">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          gridColumn={"span 10"}
        >
          <Box display="flex" width="50%">
            <SelectStore stores={stores} />
          </Box>
          <Box
            display="flex"
            //gridColumn="span 3"
            justifyContent="space-arround"
            gap="4px"
            padding="4px"
          >
            <ColorButton
              variant="contained"
              onClick={newTransaction}
              sx={{ gridColumn: "span 2" }}
            >
              <AddCircleOutlinedIcon sx={{ mr: "10px" }} />
              <Typography fontWeight="bold">
                {t("transactions.newButton")}
              </Typography>
            </ColorButton>
            <ColorButton
              variant="contained"
              onClick={uploadLedger}
              sx={{ gridColumn: "span 2" }}
            >
              <AddCircleOutlinedIcon sx={{ mr: "10px" }} />
              <Typography fontWeight="bold">
                {t("transactions.batchUploadButton")}
              </Typography>
            </ColorButton>
          </Box>
        </Box>
        <Box
          gridColumn={isNonMobile ? "span 10" : "span 10"}
          backgroundColor={colors.grey[100]}
          display="flex"
          sx={{ borderRadius: 2 }}
          flexDirection="column"
        >
          {/*<Box
              display="flex"
              justifyContent="space-between"
              flexDirection="row"
            >
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.primary[900]}
              >
                Remessas Hoje por Provedora
              </Typography>
              <Stack direction="row" spacing={1}>
                <Chip
                  size="small"
                  label={"Amount"}
                  onClick={selectAmountBar}
                  sx={{
                    backgroundColor:
                      barDataType === "Amount"
                        ? colors.primary[500]
                        : colors.grey[200],
                    color:
                      barDataType === "Amount"
                        ? colors.grey[100]
                        : colors.primary[900],
                  }}
                />
                <Chip
                  label={"Quantity"}
                  size="small"
                  onClick={selectQuantityBar}
                  sx={{
                    backgroundColor:
                      barDataType === "Quantity"
                        ? colors.primary[500]
                        : colors.grey[200],
                    color:
                      barDataType === "Quantity"
                        ? colors.grey[100]
                        : colors.primary[900],
                  }}
                />
              </Stack>
            </Box>
            <BarChart data={barData} type={barDataType} />
          </Box>
        </Box> */}

          <Box
            height="77vh"
            width="100%"
            gridColumn={"span 10"}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .name-column--cell": {
                color: "#0c1c3c",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#9f1853",
                borderBottom: "none",
                color: "white",
                maxHeight: "168px !important",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.grey[100],
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "#9f1853",
                color: "white",
              },
              "& .MuiTablePagination-root": {
                color: "white",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: `${colors.grey[100]} !Important`,
              },
              "& .super-app-theme--cell": {
                backgroundColor: "rgba(224, 183, 60, 0.55)",
                color: "#1a3e72",
                fontWeight: "600",
              },
            }}
          >
            <DataGrid
              rowHeight={25}
              ignoreDiacritics
              columnHeaderHeight={36}
              rows={pageState.data}
              rowCount={pageState.total}
              loading={pageState.isLoading}
              filterDebounceMs={700}
              paginationMode="server"
              filterMode="server"
              onFilterModelChange={onFilterChange}
              paginationModel={{ page: pageState.page - 1, pageSize: 50 }}
              onPaginationModelChange={(newPageModel) =>
                setPageState((old) => ({
                  ...old,
                  page: newPageModel.page + 1,
                }))
              }
              onPageChange={(newPage) =>
                setPageState((old) => ({ ...old, page: newPage }))
              }
              pageSizeOptions={[50]}
              getRowId={(row) => row.id}
              onRowClick={(e) => handleRowClick(e)}
              columns={columns}
              slots={{
                toolbar: QuickSearchToolbar,
                loadingOverlay: LinearProgress,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              disableColumnMenu
            />
          </Box>
        </Box>
        <Toast
          open={openAlert}
          alertMessage={alertMessage}
          setOpen={setOpenAlert}
          severity={severity}
        />
        <NewTransactionDialog
          openNewTransactionDialog={openNewTransactionDialog}
          handleClose={handleClose}
          stores={stores}
          clients={clients}
          recipients={recipients}
          storeProviders={storeProviders}
          transactions={transactions}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setOpenAlert={setOpenAlert}
          forceLoadClients={forceLoadClients}
          setForceLoadClients={setForceLoadClients}
        />
        <EditTransactionDialog
          openEditTransactionDialog={openEditTransactionDialog}
          handleClose={handleClose}
          stores={stores}
          clients={clients}
          recipients={recipients}
          storeProviders={storeProviders}
          transactions={transactions}
          transactionInfo={transactionInfo}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setOpenAlert={setOpenAlert}
        />
        <DeleteTransactionDialog
          openDeleteTransactionDialog={openDeleteTransactionDialog}
          handleClose={handleClose}
          stores={stores}
          clients={clients}
          recipients={recipients}
          storeProviders={storeProviders}
          transactions={transactions}
          transactionInfo={transactionInfo}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setOpenAlert={setOpenAlert}
        />
        <TransactionMiniReportDialog
          openTransactionMiniReportDialog={openTransactionMiniReportDialog}
          handleClose={handleCloseTransactionMiniReportDialog}
          transactions={transactions}
          transactionInfo={transactionInfo}
          stores={stores}
          clients={clients}
          storeProviders={storeProviders}
          recipients={recipients}
        />
        <UploadLedgerDialog
          openUploadLedgerDialog={openUploadLedgerDialog}
          handleClose={handleClose}
          stores={stores}
          storeProviders={storeProviders}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setOpenAlert={setOpenAlert}
        />
      </Box>
    </Box>
  );
};
export default Transactions;
