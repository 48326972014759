import { Box, Button, Typography } from "@mui/material";
import { GridToolbarQuickFilter, DataGrid } from "@mui/x-data-grid";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { tokens } from "../../theme";
import ClientDialog from "../../components/ClientDialog";
import EditClientDialog from "../../components/EditClientDialog";
import { useTheme } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import Toast from "../../components/Snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../components/NoRowsOverlay";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";

const QuickSearchToolbar = () => {
  const [openNewClientDialog, setOpenNewClientDialog] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const newClient = () => {
    setOpenNewClientDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenNewClientDialog(false);
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#004549",
    "&:hover": {
      backgroundColor: "#336a6d",
    },
  }));
  return (
    <>
      <Box
        sx={{
          p: 0.5,
          display: "flex",
          gap: "4px",
          justifyContent: "space-between",
          flexWrap: "wrap",
          backgroundColor: colors.grey[100],
          borderRadius: 2,
        }}
        //gridColumn={"span 5"}
      >
        <GridToolbarQuickFilter
          size="small"
          placeholder={t("clients.quickSearchPlaceHolder")}
          variant="outlined"
          //style={{ flex: 0.5 }}
          sx={{ width: "50%" }}
        />
        <ColorButton
          variant="contained"
          onClick={newClient}
          sx={{ width: "20%" }}
        >
          <AddCircleOutlinedIcon sx={{ mr: "10px" }} />
          <Typography fontWeight="bold">{t("clients.newButton")}</Typography>
        </ColorButton>
      </Box>
      <Toast
        open={openAlert}
        setOpen={setOpenAlert}
        alertMessage={alertMessage}
        severity={severity}
      />
      <ClientDialog
        openNewClientDialog={openNewClientDialog}
        handleClose={handleCloseDialog}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
    </>
  );
};

const Clients = () => {
  const { t } = useTranslation();
  const [stores, setStores] = useState([]);
  const [newClientDialogChange, setNewClientDialogChange] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();
  const [clientInfo, setClientInfo] = useState({});

  const [openEditClientDialog, setOpenEditClientDialog] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
  });

  const [queryOptions, setQueryOptions] = useState({
    filterModel: {
      items: [],
      logicOperator: "and",
      quickFilterValues: [],
      quickFilterLogicalOperator: "and",
    },
  });

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  useEffect(() => {
    const params = {
      page: pageState.page,
      filter: !!queryOptions.filterModel.quickFilterValues.length
        ? `${queryOptions.filterModel.quickFilterValues.join(" ")}/`
        : "",
    };

    const getClients = async () => {
      try {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }));
        const response = await axiosPrivate.get(
          `/clients/paginated/page/${params.page}/filter/${params.filter}`
        );
        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: response.data.rows,
          total: response.data.count,
        }));
      } catch (err) {}
    };

    getClients();
  }, [
    pageState.page,
    queryOptions.filterModel,
    openEditClientDialog,
    newClientDialogChange,
  ]);

  useEffect(() => {
    const getStores = async () => {
      try {
        const response = await axiosPrivate.get("/stores");
        setStores(response.data);
      } catch (err) {}
    };

    getStores();
  }, []);

  const getClientInfo = async (id) => {
    try {
      const response = await axiosPrivate.get(`/clients/${id}`);
      setClientInfo(response.data);
    } catch (err) {}
  };
  const handleCloseDialog = () => {
    setOpenEditClientDialog(false);
  };

  const handleRowClick = async (e) => {
    await getClientInfo(e.id);
    setOpenEditClientDialog(true);
  };

  const columns = [
    {
      field: "name",
      headerName: t("clients.tableColumns.name"),
      flex: 1,
      cellClassName: "name-column--cell",
    },

    {
      field: "phoneNumber",
      headerName: t("clients.tableColumns.phoneNumber"),
      flex: 1,
    },
    {
      field: "city",
      headerName: t("clients.tableColumns.city"),
      flex: 1,
      getApplyQuickFilterFn: undefined,
    },
  ];

  return (
    <Box display="grid" gridTemplateColumns="repeat(10, 1fr)" gap="4px">
      <Box
        height="76vh"
        gridColumn={"span 10"}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: "#0c1c3c",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#004549",
            borderBottom: "none",
            color: "white",
            maxHeight: "168px !important",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.grey[100],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "#004549",
            color: "white",
          },
          "& .MuiTablePagination-root": {
            color: "white",
          },
        }}
      >
        <DataGrid
          rowHeight={25}
          ignoreDiacritics
          columnHeaderHeight={36}
          rows={pageState.data}
          rowCount={pageState.total}
          loading={pageState.isLoading}
          filterDebounceMs={700}
          paginationMode="server"
          filterMode="server"
          onFilterModelChange={onFilterChange}
          paginationModel={{ page: pageState.page - 1, pageSize: 50 }}
          onPaginationModelChange={(newPageModel) =>
            setPageState((old) => ({
              ...old,
              page: newPageModel.page + 1,
            }))
          }
          onPageChange={(newPage) =>
            setPageState((old) => ({ ...old, page: newPage }))
          }
          pageSizeOptions={[50]}
          getRowId={(row) => row.id}
          onRowClick={(e) => handleRowClick(e)}
          columns={columns}
          slots={{
            toolbar: QuickSearchToolbar,

            loadingOverlay: LinearProgress,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          disableColumnMenu
        />

        <Toast
          open={openAlert}
          setOpen={setOpenAlert}
          alertMessage={alertMessage}
          severity={severity}
        />

        <EditClientDialog
          openEditClientDialog={openEditClientDialog}
          handleClose={handleCloseDialog}
          clientInfo={clientInfo}
          stores={stores}
          setAlertMessage={setAlertMessage}
          setSeverity={setSeverity}
          setOpenAlert={setOpenAlert}
        />
      </Box>
    </Box>
  );
};

export default Clients;
