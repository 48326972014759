import { useState } from "react";
import { styled } from "@mui/material/styles";

import {
  TextField,
  useTheme,
  Button,
  Typography,
  useMediaQuery,
  Autocomplete,
  Box,
} from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import useAxiosPrivate from "../../api/useAxiosPrivate";

const DELETE_RECIPIENT_URL = "/recipients";

const DeleteRecipient = (props) => {
  const {
    handleClose,
    clients,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
    recipientInfo,
  } = props;

  const pixKeyTypes = ["Celular", "CPF", "Chave Aleatória", "E-mail"];
  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [client, setClient] = useState(
    clients.filter((client) => client.id === recipientInfo.clientId)[0]
  );
  const [name, setName] = useState(recipientInfo.name);
  const [identificationDocument, setIdentificationDocument] = useState(
    recipientInfo.identificationDocument
  );
  const [bankName1, setBankName1] = useState(recipientInfo.bankName1);
  const [bankAccount1, setBankAccount1] = useState(recipientInfo.bankAccount1);
  const [bankAgency1, setBankAgency1] = useState(recipientInfo.bankAgency1);
  const [keyPix1, setKeyPix1] = useState(recipientInfo.keyPix1);
  const [typePix1, setTypePix1] = useState(recipientInfo.typePix1);
  const [bankName2, setBankName2] = useState(recipientInfo.bankName2);
  const [bankAccount2, setBankAccount2] = useState(recipientInfo.bankAccount2);
  const [bankAgency2, setBankAgency2] = useState(recipientInfo.bankAgency2);
  const [keyPix2, setKeyPix2] = useState(recipientInfo.keyPix2);
  const [typePix2, setTypePix2] = useState(recipientInfo.typePix2);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack

    try {
      const response = await axiosPrivate.delete(
        `${DELETE_RECIPIENT_URL}/${recipientInfo.id}`,
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 204) {
        setAlertMessage(`Beneficiário ${recipientInfo.name} excluído!`);
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Falha no Registro do Beneficiário");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  return (
    <Box>
      <Header
        title="REMOVER BENEFICIÁRIO"
        subtitle="ATENÇÃO: Remover este Beneficiário também irá excluir todas as remessas feitas para ele."
        button={false}
        color="#f04646"
      />

      <Box
        borderRadius="10px"
        marginTop="20px"
        padding="20px"
        backgroundColor={colors.primary[400]}
        display="grid"
        gap="15px"
        gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 4" }}
        >
          Cliente
        </Typography>

        <Box sx={{ gridColumn: "span 4" }} />

        <Autocomplete
          fullWidth
          value={client}
          disabled
          variant="outlined"
          id="nome-cliente"
          options={clients}
          getOptionLabel={(option) => `${option.name}: (${option.phoneNumber})`}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          renderInput={(params) => (
            <TextField {...params} label="Nome do Cliente" />
          )}
        />
      </Box>

      {/* PARTE DO BENEFICIÁRO */}
      {client && (
        <Box
          borderRadius="10px"
          padding="20px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gridTemplateColumns="repeat(4, 1fr)"
          gap="15px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
          }}
        >
          <Typography variant="h4" fontWeight="bold">
            Novo Beneficiário
          </Typography>
          <Box sx={{ gridColumn: "span 4" }} visibility="hidden" />
          <TextField
            required
            disabled
            size="small"
            fullWidth
            value={name}
            variant="outlined"
            type="text"
            label="Nome"
            sx={{
              backgroundColor: colors.grey[900],
              gridColumn: isNonMobile ? "span 2" : "span 4",
            }}
            onChange={(e) => setName(e.target.value)}
            //inputProps={{ readOnly: true }}
          />
          <TextField
            required
            disabled
            size="small"
            fullWidth
            value={identificationDocument}
            variant="outlined"
            type="text"
            label="Documento de Identificação"
            sx={{
              backgroundColor: colors.grey[900],
              gridColumn: isNonMobile ? "span 2" : "span 4",
            }}
            onChange={(e) => setIdentificationDocument(e.target.value)}
            //inputProps={{ readOnly: true }}
          />
          <TextField
            required
            size="small"
            fullWidth
            value={bankName1}
            variant="outlined"
            type="text"
            label="Nome do Banco 1"
            sx={{
              backgroundColor: colors.grey[900],
              gridColumn: isNonMobile ? "span 2" : "span 4",
            }}
            onChange={(e) => setBankName1(e.target.value)}
            //inputProps={{ readOnly: true }}
          />
          <TextField
            size="small"
            fullWidth
            disabled
            value={bankAgency1}
            variant="outlined"
            type="text"
            label="Agência 1"
            sx={{
              backgroundColor: colors.grey[900],
              gridColumn: isNonMobile ? "span 1" : "span 2",
            }}
            onChange={(e) => setBankAgency1(e.target.value)}
            //inputProps={{ readOnly: true }}
          />
          <TextField
            disabled
            size="small"
            fullWidth
            value={bankAccount1}
            variant="outlined"
            type="text"
            label="Conta 1"
            sx={{
              backgroundColor: colors.grey[900],
              gridColumn: isNonMobile ? "span 1" : "span 2",
            }}
            onChange={(e) => setBankAccount1(e.target.value)}
            //inputProps={{ readOnly: true }}
          />
          <Autocomplete
            disabled
            required
            fullWidth
            size="small"
            disableClearable
            value={typePix1}
            variant="outlined"
            id="pix-type"
            options={pixKeyTypes}
            sx={{
              gridColumn: isNonMobile ? "span 1" : "span 4",
              backgroundColor: colors.grey[900],
            }}
            onChange={(_, newValue) => setTypePix1(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Tipo de Chave Pix" />
            )}
          />
          <TextField
            disabled
            required
            size="small"
            fullWidth
            value={keyPix1}
            variant="outlined"
            type="text"
            label="Chave Pix 1"
            sx={{
              backgroundColor: colors.grey[900],
              gridColumn: isNonMobile ? "span 3" : "span 4",
            }}
            onChange={(e) => setKeyPix1(e.target.value)}
          />
          <Box sx={{ gridColumn: "span 4" }} />

          <TextField
            disabled
            size="small"
            fullWidth
            value={bankName2}
            variant="outlined"
            type="text"
            label="Nome do Banco 2"
            sx={{
              backgroundColor: colors.grey[900],
              gridColumn: isNonMobile ? "span 2" : "span 4",
            }}
            onChange={(e) => setBankName2(e.target.value)}
            //inputProps={{ readOnly: true }}
          />
          <TextField
            disabled
            size="small"
            fullWidth
            value={bankAgency2}
            variant="outlined"
            type="text"
            label="Agência 2"
            sx={{
              backgroundColor: colors.grey[900],
              gridColumn: isNonMobile ? "span 1" : "span 2",
            }}
            onChange={(e) => setBankAgency2(e.target.value)}
            //inputProps={{ readOnly: true }}
          />
          <TextField
            disabled
            size="small"
            fullWidth
            value={bankAccount2}
            variant="outlined"
            type="text"
            label="Conta 2"
            sx={{
              backgroundColor: colors.grey[900],
              gridColumn: isNonMobile ? "span 1" : "span 2",
            }}
            onChange={(e) => setBankAccount2(e.target.value)}
            //inputProps={{ readOnly: true }}
          />
          <Autocomplete
            disabled
            fullWidth
            size="small"
            value={typePix2}
            variant="outlined"
            id="pix-type"
            options={pixKeyTypes}
            sx={{
              gridColumn: isNonMobile ? "span 1" : "span 4",
              backgroundColor: colors.grey[900],
            }}
            onChange={(_, newValue) => setTypePix2(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Tipo de Chave Pix" />
            )}
          />
          <TextField
            disabled
            size="small"
            fullWidth
            value={keyPix2}
            variant="outlined"
            type="text"
            label="Chave Pix 2"
            sx={{
              backgroundColor: colors.grey[900],
              gridColumn: isNonMobile ? "span 3" : "span 4",
            }}
            onChange={(e) => setKeyPix2(e.target.value)}
          />
        </Box>
      )}
      {client && (
        <Box
          borderRadius="10px"
          padding="10px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gap="5px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          <Box sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }} />
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            height="100%"
            onClick={handleSubmit}
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
          >
            <Typography fontWeight="bold">Remover Beneficiário</Typography>
          </Button>
          <ColorButton
            variant="contained"
            height="100%"
            onClick={handleClose}
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
          >
            Cancelar
          </ColorButton>
        </Box>
      )}
    </Box>
  );
};
export default DeleteRecipient;
