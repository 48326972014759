import dayjs from "dayjs";

const lineDataProcessing = (data, callback) => {
  let monthData = [];
  let lineData = [{ id: "amount", color: "#002055" }];
  let i = dayjs();

  let transactions = data.filter(
    (transaction) =>
      transaction.dateTransaction >=
      dayjs().subtract(1, "month").format("YYYY-MM-DD")
  );

  while (i > dayjs().subtract(1, "month")) {
    monthData.push({ x: i.format("YYYY-MM-DD"), y: 0 });
    i = i.subtract(1, "days");
  }
  monthData.reverse();

  transactions.forEach((transaction) => {
    monthData.forEach((day) => {
      if (day.x === dayjs(transaction.dateTransaction).format("YYYY-MM-DD")) {
        day.y += transaction.amountCollected;
      }
    });
  });

  lineData[0].data = monthData;

  callback(lineData);
};

const barDataProcessing = (data, callbackAmount, callbakQuantity) => {
  let providers = [];
  let barDataAmount = [];
  let barDataQuantity = [];

  data.forEach((transaction) => {
    if (!providers.includes(transaction.storeProvider.Provider.name)) {
      barDataAmount.push({
        provider: transaction.storeProvider.Provider.name,
        amountColor: transaction.storeProvider.Provider.providerColor,
        amount: transaction.amountCollected,
      });
      barDataQuantity.push({
        provider: transaction.storeProvider.Provider.name,
        amountColor: transaction.storeProvider.Provider.providerColor,
        amount: 1,
      });
      providers.push(transaction.storeProvider.Provider.name);
    } else {
      barDataAmount.forEach((obj) => {
        if (obj.provider === transaction.storeProvider.Provider.name) {
          obj.amount += transaction.amountCollected;
        }
      });
      barDataQuantity.forEach((obj) => {
        if (obj.provider === transaction.storeProvider.Provider.name) {
          obj.amount += 1;
        }
      });
    }
  });

  callbackAmount(barDataAmount);
  callbakQuantity(barDataQuantity);
};

const summarizeClientTransactions = (
  transactions,
  storeProviders,
  callBackProviders,
  callBackTotal,
  callBackLimit
) => {
  var result = [];
  var providers = [];
  var remainingLimit = [];

  var total = {
    last7Days: 0,
    storeRemainingLimit: 10000,
    last30Days: 0,
    lastYear: 0,
    total: 0,
  };

  transactions.forEach((transaction) => {
    total = {
      ...total,
      total: total.total + transaction.amountCollected,
      last7Days:
        dayjs(transaction.dateTransaction).format("YY-MM-DD") >=
        dayjs().subtract(7, "days").format("YY-MM-DD")
          ? total.last7Days + transaction.amountCollected
          : total.last7Days,
      storeRemainingLimit:
        dayjs(transaction.dateTransaction).format("YY-MM-DD") >=
        dayjs().subtract(7, "days").format("YY-MM-DD")
          ? total.storeRemainingLimit - transaction.amountCollected
          : total.storeRemainingLimit,
      last30Days:
        dayjs(transaction.dateTransaction).format("YY-MM-DD") >=
        dayjs().subtract(1, "month").format("YY-MM-DD")
          ? total.last30Days + transaction.amountCollected
          : total.last30Days,
      lastYear:
        dayjs(transaction.dateTransaction).format("YY-MM-DD") >=
        dayjs().subtract(1, "year").format("YY-MM-DD")
          ? total.lastYear + transaction.amountCollected
          : total.lastYear,
    };

    if (!providers.includes(transaction.storeProvider.Provider.name)) {
      providers.push(transaction.storeProvider.Provider.name);
      result.push({
        providerId: transaction.storeProvider.Provider.id,
        providerName: transaction.storeProvider.Provider.name,
        providerDailyLimit: transaction.storeProvider.customDailyLimit,
        providerMonthlyLimit: transaction.storeProvider.customMonthlyLimit,
        providerAnnualLimit: transaction.storeProvider.customAnnualLimit,
        total: transaction.amountCollected,
        last7Days:
          dayjs(transaction.dateTransaction).format("YY-MM-DD") >=
          dayjs().subtract(7, "days").format("YY-MM-DD")
            ? transaction.amountCollected
            : 0,
        last30Days:
          dayjs(transaction.dateTransaction).format("YY-MM-DD") >=
          dayjs().subtract(1, "month").format("YY-MM-DD")
            ? transaction.amountCollected
            : 0,
        lastYear:
          dayjs(transaction.dateTransaction).format("YY-MM-DD") >=
          dayjs().subtract(1, "year").format("YY-MM-DD")
            ? transaction.amountCollected
            : 0,
      });

      remainingLimit.push({
        providerId: transaction.storeProvider.Provider.id,
        providerName: transaction.storeProvider.Provider.name,
        dailyLimit:
          dayjs(transaction.dateTransaction).format("YY-MM-DD") >=
          dayjs().subtract(7, "days").format("YY-MM-DD")
            ? transaction.storeProvider.customDailyLimit -
              transaction.amountCollected
            : transaction.storeProvider.customDailyLimit,
        monthlyLimit:
          dayjs(transaction.dateTransaction).format("YY-MM-DD") >=
          dayjs().subtract(1, "month").format("YY-MM-DD")
            ? transaction.storeProvider.customMonthlyLimit -
              transaction.amountCollected
            : transaction.storeProvider.customMonthlyLimit,
        annualLimit:
          dayjs(transaction.dateTransaction).format("YY-MM-DD") >=
          dayjs().subtract(1, "year").format("YY-MM-DD")
            ? transaction.storeProvider.customAnnualLimit -
              transaction.amountCollected
            : transaction.storeProvider.customAnnualLimit,
      });
    } else {
      let objIndex = result.findIndex(
        (obj) => obj.providerId == transaction.storeProvider.Provider.id
      );
      result[objIndex] = {
        ...result[objIndex],
        total: result[objIndex].total + transaction.amountCollected,

        last7Days:
          dayjs(transaction.dateTransaction).format("YY-MM-DD") >=
          dayjs().subtract(7, "days").format("YY-MM-DD")
            ? result[objIndex].last7Days + transaction.amountCollected
            : result[objIndex].last7Days,
        last30Days:
          dayjs(transaction.dateTransaction).format("YY-MM-DD") >=
          dayjs().subtract(1, "month").format("YY-MM-DD")
            ? result[objIndex].last30Days + transaction.amountCollected
            : result[objIndex].last30Days,
        lastYear:
          dayjs(transaction.dateTransaction).format("YY-MM-DD") >=
          dayjs().subtract(1, "year").format("YY-MM-DD")
            ? result[objIndex].lastYear + transaction.amountCollected
            : result[objIndex].lastYear,
      };

      let objIndex2 = remainingLimit.findIndex(
        (obj) => obj.providerId == transaction.storeProvider.Provider.id
      );
      remainingLimit[objIndex2] = {
        ...remainingLimit[objIndex2],

        dailyLimit:
          dayjs(transaction.dateTransaction).format("YY-MM-DD") >=
          dayjs().subtract(7, "days").format("YY-MM-DD")
            ? remainingLimit[objIndex2].dailyLimit - transaction.amountCollected
            : remainingLimit[objIndex2].dailyLimit,
        monthlyLimit:
          dayjs(transaction.dateTransaction).format("YY-MM-DD") >=
          dayjs().subtract(1, "month").format("YY-MM-DD")
            ? remainingLimit[objIndex2].monthlyLimit -
              transaction.amountCollected
            : remainingLimit[objIndex2].monthlyLimit,
        annualLimit:
          dayjs(transaction.dateTransaction).format("YY-MM-DD") >=
          dayjs().subtract(1, "year").format("YY-MM-DD")
            ? remainingLimit[objIndex2].annualLimit -
              transaction.amountCollected
            : remainingLimit[objIndex2].annualLimit,
      };
    }
  });

  let limitArray = [];
  remainingLimit.forEach((provider) => {
    let numericArray = Object.values(provider).filter(
      (num) => typeof num === "number"
    );
    let providerName = provider.providerName;

    if (Math.min(...numericArray) < total.storeRemainingLimit) {
      limitArray.push({
        provider: providerName,
        limit: Math.min(...numericArray),
      });
    } else {
      limitArray.push({
        provider: providerName,
        limit: total.storeRemainingLimit,
      });
    }
  });

  storeProviders.forEach((provider) => {
    if (!providers.includes(provider.Provider.name)) {
      limitArray.push({
        provider: provider.Provider.name,
        limit: Math.min(total.storeRemainingLimit, provider.customDailyLimit),
      });
    }
  });

  callBackTotal(total);
  callBackProviders(result);
  callBackLimit(limitArray);
};

export { barDataProcessing, summarizeClientTransactions };
