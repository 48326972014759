import { Box, Button, useTheme, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { tokens } from "../../theme";
import { styled } from "@mui/material/styles";

const ProviderLimitAlert = (props) => {
  const { handleClose } = props;

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  return (
    <Box>
      <Box
        borderRadius="10px"
        padding="20px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="15px"
        gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 4" },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 4" }}
        >
          Alerta de Limite Excedido
        </Typography>
        <Typography variant="h5" sx={{ gridColumn: "span 4" }}>
          Ao registrar essa remessa com essa Provedora o Cliente estará
          excedendo o limite definido no período para envios sem documentação
          adicional.
        </Typography>
        <Typography variant="h5" sx={{ gridColumn: "span 4" }}>
          É recomendado que você adicione uma Prova de Fundos caso o cliente
          ainda não possua.
        </Typography>
      </Box>

      <Box height="60px">
        <Box
          borderRadius="10px"
          padding="10px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.grey[100]}
          display="grid"
          gap="5px"
          sx={{
            "& > div": { gridColumns: "span 4" },
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          {isNonMobile && <Box gridColumn={"span 3"} />}
          <ColorButton
            variant="contained"
            height="100%"
            onClick={handleClose}
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
          >
            ESTOU CIENTE
          </ColorButton>
        </Box>
      </Box>
      <Box />
    </Box>
  );
};

export default ProviderLimitAlert;
