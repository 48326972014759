import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle, Button } from "@mui/material";
import ProviderLimitAlert from "../scenes/transactions/providerLimitAlert";

export default function ProviderLimitDialog(props) {
  const { open, handleClose } = props;

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>
          <ProviderLimitAlert handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
