import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DeleteClient from "../scenes/Clients/deleteClient";

export default function DeleteClientDialog(props) {
  const {
    openDeleteClientDialog,
    handleCloseDialog,
    handleCloseEditDialog,
    clientInfo,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
  } = props;

  return (
    <div>
      <Dialog
        open={openDeleteClientDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <DeleteClient
            clientInfo={clientInfo}
            handleCloseDialog={handleCloseDialog}
            handleCloseEditDialog={handleCloseEditDialog}
            setAlertMessage={setAlertMessage}
            setOpenAlert={setOpenAlert}
            setSeverity={setSeverity}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
