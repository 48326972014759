import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DeleteStore from "../scenes/settings/stores/deleteStore";

export default function DeleteStoreDialog(props) {
  const {
    store_info,
    openDeleteStoreDialog,
    handleClose,
    setAlertMessage,
    setSeverity,
    setOpenAlert,
  } = props;

  return (
    <div>
      <Dialog
        open={openDeleteStoreDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <DeleteStore
            store_info={store_info}
            handleClose={handleClose}
            setAlertMessage={setAlertMessage}
            setSeverity={setSeverity}
            setOpenAlert={setOpenAlert}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
