import { useState } from "react";
import Image from "../register/Design-sem-nome.jpg";
import msb from "../register/logo.png";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import Toast from "../../components/Snackbar";

import axios from "../../api/axios.js";
const RESET_PASSWORD_URL = "/reset_password";

const ResetPassword = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [email, setEmail] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [activeButton, setActiveButton] = useState(true);

  const handleSubmit = async (e) => {
    setActiveButton(false);
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = email;
    if (!v1) {
      setAlertMessage("Coloque um endereço de e-mail");
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    try {
      const response = await axios.post(
        RESET_PASSWORD_URL,
        JSON.stringify({
          email,
        }),
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );

      setAlertMessage(
        "Enviamos um e-mail com as instruções para reset de senha"
      );
      setSeverity("success");
      setOpenAlert(true);

      setEmail("");
      setActiveButton(true);
    } catch (err) {
      if (err.response.status === 535) {
        setAlertMessage(
          "Estamos com um problema e não pudemos enviar o e-mail"
        );
        setSeverity("error");
        setOpenAlert(true);
        setActiveButton(true);
      } else if (err.response.status === 401) {
        setAlertMessage("Não pudemos encontrar o e-mail");
        setSeverity("error");
        setOpenAlert(true);
        setActiveButton(true);
      } else {
        setAlertMessage("O servidor não está respondendo. Tente mais tarde");
        setSeverity("error");
        setOpenAlert(true);
        setActiveButton(true);
      }
      //errRef.current.focus();
    }
  };

  return (
    <>
      <Toast
        open={openAlert}
        setOpen={setOpenAlert}
        alertMessage={alertMessage}
        severity={severity}
      />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${Image})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid
          item
          component={Paper}
          elevation={6}
          square
          backgroundColor={colors.secondary}
          width="50vh"
          height="100vh"
          position="absolute"
          right={0}
        >
          <Box
            sx={{
              my: 8,
              mx: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {<img src={msb} height="70px" alt="logo" />}

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
              //textAlign={"center"}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                autoFocus
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Button
                  disabled={!email || !activeButton ? true : false}
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}

                  //asascolor={colors.blueAccent[600]}
                >
                  Continuar
                </Button>
                <Link
                  href="/login  "
                  variant="body2"
                  color="rgb(12,28,60)"
                  sx={[
                    {
                      "&:visited": {
                        color: "#0c64fb",
                      },
                    },
                  ]}
                >
                  {"Fazer login com senha"}
                </Link>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ResetPassword;
