import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import NewStore from '../scenes/settings/stores/newStore';

export default function StoreDialog(props) {

  const { openNewStoreDialog, handleClose, setAlertMessage, setSeverity, setOpenAlert} = props

  return (

    
    <div>
      <Dialog open={openNewStoreDialog} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>
          <NewStore handleClose={handleClose} setAlertMessage={setAlertMessage} setSeverity={setSeverity} setOpenAlert={setOpenAlert}/>
        </DialogContent>

      </Dialog>
    </div>
  );
}