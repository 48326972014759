import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DeleteTransaction from "../scenes/transactions/deleteTransaction";

export default function DeleteTransactionDialog(props) {
  const {
    openDeleteTransactionDialog,
    handleCloseEditTransactionDialog,
    handleClose,
    transactionId,
    setAlertMessage,
    setSeverity,
    setOpenAlert,
  } = props;

  return (
    <div>
      <Dialog
        open={openDeleteTransactionDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <DeleteTransaction
            openDeleteTransactionDialog={openDeleteTransactionDialog}
            handleClose={handleClose}
            handleCloseEditTransactionDialog={handleCloseEditTransactionDialog}
            transactionId={transactionId}
            setAlertMessage={setAlertMessage}
            setSeverity={setSeverity}
            setOpenAlert={setOpenAlert}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
