import { useState, useEffect } from "react";
import { Box, TextField, useTheme, Button, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import useAxiosPrivate from "../../../api/useAxiosPrivate";
import { styled } from "@mui/material/styles";

const NEW_USER_URL = "/users/employee";

const NewUser = (props) => {
  const { handleClose, setAlertMessage, setOpenAlert, setSeverity } = props;

  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const PASS_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");

  const [validEmail, setValidEmail] = useState(false);
  const [validPass, setValidPass] = useState(false);

  const [matchPass, setMatchPass] = useState("");
  const [validMatch, setValidMatch] = useState(false);

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack

    if (!name || !phoneNumber || !validEmail || !validPass || !validMatch) {
      setAlertMessage("Verfifique os campos obrigatórios");
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    try {
      const response = await axiosPrivate.post(
        NEW_USER_URL,
        JSON.stringify({
          name,
          phoneNumber,
          email,
          password,
        }),
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setAlertMessage(`Novo Usuário Registrado!`);
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message);
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Houve um erro na criação do Usuário");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email) || !email);
  }, [email]);

  useEffect(() => {
    setValidPass(PASS_REGEX.test(password) || !password);
    setValidMatch(password === matchPass);
  }, [password, matchPass]);

  return (
    <Box>
      <Header
        title="NOVO USUÁRIO"
        subtitle="Cadastre um Novo Usuário"
        button={false}
        color="#621B74"
      />
      <Box
        borderRadius="10px"
        padding="20px"
        backgroundColor={colors.primary[400]}
        display="grid"
        gap="15px"
        gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 4" },
        }}
      >
        <TextField
          id="name"
          fullWidth
          label="Nome"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          sx={{ gridColumn: "span 4" }}
        />

        <TextField
          id="phoneNumber"
          label="Telefone"
          variant="outlined"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          required
        />

        <TextField
          required
          id="email"
          label="E-mail"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
        />

        <TextField
          required
          error={!validPass}
          helperText={
            !validPass &&
            "A senha deve conter no mínimo 8 caracteres, uma letra e um número"
          }
          id="password"
          label="Senha"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
        />

        <TextField
          error={!validMatch}
          helperText={!validMatch && "As senhas não conferem"}
          type="password"
          value={matchPass}
          onChange={(e) => setMatchPass(e.target.value)}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          id="confirm-password"
          label="Confirmar Senha"
          variant="outlined"
        />

        <Box sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }} />

        <Button
          type="submit"
          disabled={
            !name || !phoneNumber || !validEmail || !validPass || !validMatch
          }
          color="secondary"
          variant="contained"
          height="100%"
          onClick={handleSubmit}
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
        >
          <Typography fontWeight="bold">Registrar Usuário</Typography>
        </Button>
        <ColorButton
          variant="contained"
          height="100%"
          onClick={handleClose}
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
        >
          Cancelar
        </ColorButton>
      </Box>
    </Box>
  );
};

export default NewUser;
