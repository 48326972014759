import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../theme";

const StatBox = ({ title, subtitle, icon, increase }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box width="100%" m="8px 8px" height="12vh">
      <Box display="flex" justifyContent="space-between">
        <Box padding="4px">{icon}</Box>
        <Typography
          fontWeight="bold"
          padding="4px"
          sx={{ color: colors.primary[900] }}
        >
          {subtitle}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box />
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ color: colors.primary[700] }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

export default StatBox;
