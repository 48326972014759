import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import EditTransaction from "../scenes/transactions/editTransaction";

export default function EditTransactionDialog(props) {
  const {
    openEditTransactionDialog,
    handleClose,
    clients,
    stores,
    recipients,
    storeProviders,
    transactions,
    transactionInfo,
    setAlertMessage,
    setSeverity,
    setOpenAlert,
  } = props;

  return (
    <div>
      <Dialog
        open={openEditTransactionDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <EditTransaction
            openEditTransactionDialog={openEditTransactionDialog}
            handleClose={handleClose}
            stores={stores}
            clients={clients}
            recipients={recipients}
            storeProviders={storeProviders}
            transactions={transactions}
            transactionInfo={transactionInfo}
            setAlertMessage={setAlertMessage}
            setSeverity={setSeverity}
            setOpenAlert={setOpenAlert}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
