import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TransactionMiniReport from "../scenes/transactions/transactionMiniReport";

export default function TransactionMiniReportDialog(props) {
  const {
    openTransactionMiniReportDialog,
    handleClose,
    transactionInfo,
    stores,
    clients,
    storeProviders,
    recipients,
  } = props;

  return (
    <div>
      <Dialog
        open={openTransactionMiniReportDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <TransactionMiniReport
            transactionInfo={transactionInfo}
            handleClose={handleClose}
            stores={stores}
            clients={clients}
            storeProviders={storeProviders}
            recipients={recipients}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
