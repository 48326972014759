import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DeleteFile from "../components/DeleteFile";

export default function DeleteFileDialog(props) {
  const {
    openDeleteClientDialog,
    handleClose,
    fileInfo,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
  } = props;

  return (
    <div>
      <Dialog
        open={openDeleteClientDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <DeleteFile
            fileInfo={fileInfo}
            handleClose={handleClose}
            setAlertMessage={setAlertMessage}
            setOpenAlert={setOpenAlert}
            setSeverity={setSeverity}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
