import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Header from "../../components/Header";
import TransactionGeneralReport from "./transactionReports";
import ClientBirthdayReport from "./clientReports";
import ReccuringClientsReport from "./clientReports/recurringClients";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import useAxiosPrivate from "../../api/useAxiosPrivate";

export default function SettingsTab() {
  const colors = tokens(useTheme().palette.mode);
  const [value, setValue] = React.useState("1");
  const [show, setShow] = React.useState(false);
  const axiosPrivate = useAxiosPrivate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get("/users/storeChain");
        setShow(true);
      } catch (err) {}
    };

    getUsers();
  }, []);

  return (
    <Box m="8px">
      <Header
        title="Relatórios"
        subtitle="Obtenha dados sobre a sua Empresa"
        color="#62929E"
      />
      {show ? (
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              scrollButtons="auto"
              allowScrollButtonsMobile
              indicatorColor="primary"
              textColor="inherit"
              onChange={handleChange}
              aria-label="Alterne entre opções"
            >
              <Tab label="Transações" value="1" />

              <Tab label="Clientes (Aniversariantes)" value="2" />
              <Tab label="Clientes (Recorrência)" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ paddingTop: 1, pl: 0, pr: 0 }}>
            {<TransactionGeneralReport />}
          </TabPanel>

          <TabPanel value="2" sx={{ paddingTop: 1, pl: 0, pr: 0 }}>
            {<ClientBirthdayReport />}
          </TabPanel>
          <TabPanel value="3" sx={{ paddingTop: 1, pl: 0, pr: 0 }}>
            {<ReccuringClientsReport />}
          </TabPanel>
        </TabContext>
      ) : (
        <Box
          sx={{
            borderRadius: 3,
            autoComplete: "off",
            padding: 3,
            gap: 2,
            bgcolor: colors.primary[400],
            display: "grid",
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            Não Autorizado
          </Typography>
        </Box>
      )}
    </Box>
  );
}
