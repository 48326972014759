import { Box, Button, TextField, useTheme, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";
import { styled } from "@mui/material/styles";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

const TransactionMiniReport = (props) => {
  const {
    handleClose,
    stores,
    clients,
    recipients,
    transactionInfo,
    storeProviders,
  } = props;

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const store = stores.filter(
    (store) => store.id === transactionInfo.storeId
  )[0];
  const client = clients.filter(
    (client) => client.id === transactionInfo.clientId
  )[0];
  const recipient = recipients.filter(
    (recipient) => recipient.id === transactionInfo.recipientId
  )[0];
  const storeProvider = storeProviders.filter(
    (storeProvider) => storeProvider.id === transactionInfo.storeProvider.id
  )[0];

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  return (
    <Box>
      <Header title="INFORMAÇÕES DA REMESSA" button={false} color="#9f1853" />

      <Box
        borderRadius="10px"
        padding="20px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="15px"
        gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 4" },
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 4" }}
        >
          Loja
        </Typography>

        <TextField
          size="small"
          label="Loja"
          inputProps={{ readOnly: true }}
          fullWidth
          value={store.name}
          variant="outlined"
          id="country"
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
        />
        <TextField
          size="small"
          label="Pais de Destino"
          inputProps={{ readOnly: true }}
          fullWidth
          value={transactionInfo.destinyCountry}
          variant="outlined"
          id="country"
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
        />
        <Box sx={{ gridColumn: "span 2" }}></Box>
      </Box>
      <Box
        borderRadius="10px"
        marginTop="20px"
        padding="20px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="15px"
        gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 4" },
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          Cliente
        </Typography>

        <Box sx={{ gridColumn: "span 4" }} />

        <TextField
          label="Nome do Cliente"
          fullWidth
          size="small"
          value={client.name}
          variant="outlined"
          id="nome-cliente"
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          inputProps={{ readOnly: true }}
        />

        <TextField
          size="small"
          fullWidth
          value={client.phoneNumber}
          variant="outlined"
          type="text"
          label="Telefone"
          //name="Telefone"
          sx={{
            backgroundColor: colors.grey[100],
            gridColumn: isNonMobile ? "span 1" : "span 2",
          }}
          inputProps={{ readOnly: true }}
        />
        <TextField
          size="small"
          fullWidth
          value={client.city}
          variant="outlined"
          type="text"
          label="Cidade"
          //name="Telefone"
          sx={{
            backgroundColor: colors.grey[100],
            gridColumn: isNonMobile ? "span 1" : "span 2",
          }}
          inputProps={{ readOnly: true }}
        />
      </Box>

      {/* PARTE DO BENEFICIÁRO */}
      <Box
        borderRadius="10px"
        padding="20px"
        marginTop="20px"
        marginBottom="20px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gridTemplateColumns="repeat(4, 1fr)"
        gap="15px"
        sx={{
          "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
        }}
      >
        <Typography variant="h4" fontWeight="bold">
          Beneficiário
        </Typography>
        <Box sx={{ gridColumn: "span 4" }} visibility="hidden" />
        <TextField
          size="small"
          label="Nome do Beneficiário"
          value={recipient.name}
          variant="outlined"
          id="nome-beneficiario"
          sx={{ gridColumn: isNonMobile ? "span 3" : "span 2" }}
          inputProps={{ readOnly: true }}
        />

        <TextField
          size="small"
          fullWidth
          value={recipient.identificationDocument}
          variant="outlined"
          type="text"
          label="Documento"
          sx={{
            backgroundColor: colors.grey[100],
            gridColumn: isNonMobile ? "span 1" : "span 2",
          }}
          inputProps={{ readOnly: true }}
        />

        <Box sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }} />

        <TextField
          size="small"
          fullWidth
          value={
            transactionInfo.bankName === "null" ? "-" : transactionInfo.bankName
          }
          variant="outlined"
          type="text"
          label="Banco"
          sx={{
            backgroundColor: colors.grey[100],
            gridColumn: isNonMobile ? "span 1" : "span 2",
          }}
          inputProps={{ readOnly: true }}
        />

        <TextField
          size="small"
          fullWidth
          value={
            transactionInfo.bankBranch === "null"
              ? "-"
              : transactionInfo.bankBranch
          }
          variant="outlined"
          type="text"
          label="Agência"
          sx={{
            backgroundColor: colors.grey[100],
            gridColumn: isNonMobile ? "span 1" : "span 2",
          }}
          inputProps={{ readOnly: true }}
        />

        <TextField
          size="small"
          fullWidth
          value={
            transactionInfo.bankAccount === "null"
              ? "-"
              : transactionInfo.bankAccount
          }
          variant="outlined"
          type="text"
          label="Conta"
          sx={{
            backgroundColor: colors.grey[100],
            gridColumn: isNonMobile ? "span 1" : "span 2",
          }}
          inputProps={{ readOnly: true }}
        />
        <Box
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
          visibility={false}
        />
        <TextField
          size="small"
          fullWidth
          value={
            transactionInfo.pixKeyType === "null"
              ? "-"
              : transactionInfo.pixKeyType
          }
          variant="outlined"
          type="text"
          label="Tipo de Chave Pix"
          sx={{
            backgroundColor: colors.grey[100],
            gridColumn: isNonMobile ? "span 1" : "span 2",
          }}
          inputProps={{ readOnly: true }}
        />
        <TextField
          size="small"
          fullWidth
          value={
            transactionInfo.pixKey === "null" ? "-" : transactionInfo.pixKey
          }
          variant="outlined"
          type="text"
          label="Chave Pix"
          sx={{
            backgroundColor: colors.grey[100],
            gridColumn: "span 2",
          }}
          inputProps={{ readOnly: true }}
        />
      </Box>

      {/* PARTE DA REMESSA */}

      <Box
        borderRadius="10px"
        padding="20px"
        marginTop="20px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="15px"
        sx={{
          "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
          gridTemplateColumns: "repeat(4, 1fr)",
        }}
      >
        <Box sx={{ gridColumn: "span 4" }}>
          <Typography variant="h4" fontWeight="bold">
            Remessa
          </Typography>
        </Box>

        <DatePicker
          readOnly
          disableFuture
          label="Data da Remessa (AAAA-MM-DD)"
          value={dayjs(transactionInfo.dateTransaction)}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          format="YYYY-MM-DD"
          slotProps={{ textField: { size: "small" } }}
        />
        <NumericFormat
          size="small"
          inputProps={{ readOnly: true }}
          decimalScale={2}
          fixedDecimalScale
          value={transactionInfo.amount}
          customInput={TextField}
          id="amount"
          variant="outlined"
          type="text"
          label="Valor da Remessa com Taxas"
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">USD </InputAdornment>
            ),
            sx: { "& input": { textAlign: "right" } },
          }}
        />

        <TextField
          fullWidth
          label="Forma de Pagamento"
          size="small"
          value={transactionInfo.paymentMethod}
          variant="outlined"
          id="payment-method"
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          inputProps={{ readOnly: true }}
        />

        <TextField
          fullWidth
          label="Provedora"
          size="small"
          value={storeProvider.Provider.name}
          variant="outlined"
          id="payment-method"
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
          inputProps={{ readOnly: true }}
        />
        <NumericFormat
          decimalScale={2}
          size="small"
          inputProps={{ readOnly: true }}
          fixedDecimalScale
          thousandSeparator=","
          value={transactionInfo.fee}
          customInput={TextField}
          id="fee"
          variant="outlined"
          type="text"
          label="Taxa"
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">USD </InputAdornment>
            ),
            sx: { "& input": { textAlign: "right" } },
          }}
        />

        <NumericFormat
          size="small"
          decimalScale={2}
          inputProps={{ readOnly: true }}
          thousandSeparator=","
          value={transactionInfo.quotationProvider}
          customInput={TextField}
          id="quotation-provider"
          variant="outlined"
          type="text"
          label="Cotação da Provedora"
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">BRL </InputAdornment>
            ),
            sx: { "& input": { textAlign: "right" } },
          }}
        />
        <Box
          display="grid"
          rowGap={isNonMobile ? "0px" : "15px"}
          columnGap="15px"
          gridTemplateColumns="repeat(3, 1)"
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          border="0px"
        >
          <Box sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }} />
          <NumericFormat
            size="small"
            inputProps={{ readOnly: true }}
            decimalScale={2}
            thousandSeparator=","
            value={transactionInfo.quotationClient}
            customInput={TextField}
            id="quotation-client"
            variant="standard"
            type="text"
            label="Cotação do Cliente"
            sx={{
              gridColumn: isNonMobile ? "span 1" : "span 4",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">BRL </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />

          <NumericFormat
            size="small"
            inputProps={{ readOnly: true }}
            decimalScale={2}
            thousandSeparator=","
            value={transactionInfo.spread}
            customInput={TextField}
            id="spread"
            variant="standard"
            type="text"
            label="Spread"
            sx={{
              gridColumn: isNonMobile ? "span 1" : "span 4",
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">BRL </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
        </Box>
        <NumericFormat
          size="small"
          inputProps={{ readOnly: true }}
          decimalScale={2}
          thousandSeparator=","
          value={transactionInfo.profit}
          customInput={TextField}
          id="profit"
          variant="outlined"
          type="text"
          label="Lucro"
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">USD </InputAdornment>
            ),
            sx: { "& input": { textAlign: "right" } },
          }}
        />
        {transactionInfo.receiptURL && (
          <Button
            startIcon={<CloudDownloadOutlinedIcon />}
            href={transactionInfo.receiptURL}
            variant="contained"
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
          >
            BAIXAR RECIBO
          </Button>
        )}
      </Box>

      <Box height="20px">
        <Box
          borderRadius="10px"
          padding="10px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.grey[100]}
          display="grid"
          gap="5px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          <Box sx={{ gridColumn: "span 4" }} />
          <Typography sx={{ gridColumn: isNonMobile ? "span 3" : "span 4" }}>
            Responsável: {transactionInfo.createdBy}
          </Typography>

          <ColorButton
            variant="contained"
            height="100%"
            onClick={handleClose}
            sx={{ gridColumn: isNonMobile ? "span 1" : "span 4" }}
          >
            FECHAR
          </ColorButton>
        </Box>
      </Box>
      <Box />
    </Box>
  );
};

export default TransactionMiniReport;
