import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DeleteUser from "../scenes/settings/users/deleteUser";

export default function DeleteUserDialog(props) {
  const {
    openDeleteUserDialog,
    handleClose,
    user_info,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
  } = props;

  return (
    <div>
      <Dialog
        open={openDeleteUserDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <DeleteUser
            user_info={user_info}
            handleClose={handleClose}
            setAlertMessage={setAlertMessage}
            setOpenAlert={setOpenAlert}
            setSeverity={setSeverity}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
