import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
//import Clients from "..";
//import Recipients from "../../Recipients";
import Providers from "../admin/providers/index";
import Header from "../../components/Header";

export default function AdminTab() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box m="20px" marginLeft="300px">
      <Header
        title="Administrador"
        subtitle="Ferramentas de Adminstração do Sistema"
      />
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            scrollButtons="auto"
            allowScrollButtonsMobile
            indicatorColor="secondary"
            textColor="inherit"
            onChange={handleChange}
          >
            <Tab label="Provedoras" value="1" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Providers />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
