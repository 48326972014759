import dayjs from "dayjs";

const pieDataProcessing = (
  data,
  callBackWeekAmount,
  callBackMonthAmount,
  callBackSemesterAmount,
  callBackWeekQuantity,
  callBackMonthQuantity,
  callBackSemesterQuantity
) => {
  let providersName = [];
  let providers = [];

  let weekData = [];
  let monthData = [];
  let semesterData = [];
  let weekAmountPie = [];
  let monthAmountPie = [];
  let semesterAmountPie = [];

  let weekQuantityPie = [];
  let monthQuantityPie = [];
  let semesterQuantityPie = [];

  weekData = data.filter((transaction) => {
    return (
      dayjs(transaction.dateTransaction).format("YYYY-MM-DD") >
      dayjs().subtract(7, "days").format("YYYY-MM-DD")
    );
  });
  monthData = data.filter((transaction) => {
    return (
      dayjs(transaction.dateTransaction).format("YYYY-MM-DD") >
      dayjs().subtract(1, "month").format("YYYY-MM-DD")
    );
  });

  semesterData = data.filter((transaction) => {
    return (
      dayjs(transaction.dateTransaction).format("YYYY-MM-DD") >
      dayjs().subtract(6, "months").format("YYYY-MM-DD")
    );
  });

  semesterData.forEach((transaction) => {
    if (!providersName.includes(transaction.storeProvider.Provider.name)) {
      providersName.push(transaction.storeProvider.Provider.name);
      providers.push(transaction.storeProvider.Provider);
    }
  });

  providers.forEach((provider) => {
    weekAmountPie.push({
      providerId: provider.name,
      label: provider.name,
      value: 0,
      color: provider.providerColor,
    });
    monthAmountPie.push({
      providerId: provider.name,
      label: provider.name,
      value: 0,
      color: provider.providerColor,
    });
    semesterAmountPie.push({
      providerId: provider.name,
      label: provider.name,
      value: 0,
      color: provider.providerColor,
    });
    weekQuantityPie.push({
      providerId: provider.name,
      label: provider.name,
      value: 0,
      color: provider.providerColor,
    });
    monthQuantityPie.push({
      providerId: provider.name,
      label: provider.name,
      value: 0,
      color: provider.providerColor,
    });
    semesterQuantityPie.push({
      providerId: provider.name,
      label: provider.name,
      value: 0,
      color: provider.providerColor,
    });
  });

  weekAmountPie.forEach((slice) => {
    weekData.forEach((transaction) => {
      if (slice.providerId === transaction.storeProvider.Provider.name) {
        slice.value += transaction.amountCollected;
      }
    });
  });

  monthAmountPie.forEach((slice) => {
    monthData.forEach((transaction) => {
      if (slice.providerId === transaction.storeProvider.Provider.name) {
        slice.value += transaction.amountCollected;
      }
    });
  });

  semesterAmountPie.forEach((slice) => {
    semesterData.forEach((transaction) => {
      if (slice.providerId === transaction.storeProvider.Provider.name) {
        slice.value += transaction.amountCollected;
      }
    });
  });

  weekQuantityPie.forEach((slice) => {
    weekData.forEach((transaction) => {
      if (slice.providerId === transaction.storeProvider.Provider.name) {
        slice.value += 1;
      }
    });
  });

  monthQuantityPie.forEach((slice) => {
    monthData.forEach((transaction) => {
      if (slice.providerId === transaction.storeProvider.Provider.name) {
        slice.value += 1;
      }
    });
  });

  semesterQuantityPie.forEach((slice) => {
    semesterData.forEach((transaction) => {
      if (slice.providerId === transaction.storeProvider.Provider.name) {
        slice.value += 1;
      }
    });
  });

  callBackWeekAmount(weekAmountPie.filter((slice) => slice.value > 0));
  callBackMonthAmount(monthAmountPie.filter((slice) => slice.value > 0));
  callBackSemesterAmount(semesterAmountPie.filter((slice) => slice.value > 0));
  callBackWeekQuantity(weekQuantityPie.filter((slice) => slice.value > 0));
  callBackMonthQuantity(monthQuantityPie.filter((slice) => slice.value > 0));
  callBackSemesterQuantity(
    semesterQuantityPie.filter((slice) => slice.value > 0)
  );
};

const statsBoxContentProcessing = (data) => {
  let last7DaysAmount = 0;
  let last30DaysAmount = 0;
  let last6MonthsAmount = 0;
  let todayAmount = 0;

  var todayQuantity = data.filter((transaction) => {
    return (
      dayjs(transaction.dateTransaction).format("YYYY-MM-DD") ==
      dayjs().format("YYYY-MM-DD")
    );
  });

  var last7DaysQuantity = data.filter((transaction) => {
    return (
      dayjs(transaction.dateTransaction).format("YYYY-MM-DD") >
      dayjs().subtract(7, "days").format("YYYY-MM-DD")
    );
  });

  var last30DaysQuantity = data.filter((transaction) => {
    return (
      dayjs(transaction.dateTransaction).format("YYYY-MM-DD") >
      dayjs().subtract(1, "month").format("YYYY-MM-DD")
    );
  });
  var last6MonthsQuantity = data.filter((transaction) => {
    return (
      dayjs(transaction.dateTransaction).format("YYYY-MM-DD") >
      dayjs().subtract(6, "month").format("YYYY-MM-DD")
    );
  });

  todayAmount = todayQuantity.reduce((accumulator, object) => {
    return accumulator + object.amountCollected;
  }, 0);

  last7DaysAmount = last7DaysQuantity.reduce((accumulator, object) => {
    return accumulator + object.amountCollected;
  }, 0);

  last30DaysAmount = last30DaysQuantity.reduce((accumulator, object) => {
    return accumulator + object.amountCollected;
  }, 0);

  last6MonthsAmount = last6MonthsQuantity.reduce((accumulator, object) => {
    return accumulator + object.amountCollected;
  }, 0);

  const stats = {
    amountSentToday: todayAmount,
    amountSentWeek: last7DaysAmount,
    amountSentMonth: last30DaysAmount,
    amountSentSemester: last6MonthsAmount,
    quantitySentToday: todayQuantity.length,
    quantitySentWeek: last7DaysQuantity.length,
    quantitySentMonth: last30DaysQuantity.length,
    quantitySentSemester: last6MonthsQuantity.length,
  };

  return stats;
};

const barDataProcessing = (
  data,
  callBackWeek,
  callBackMonth,
  callBackSemester
) => {
  let weekCalendar = [];
  let monthCalendar = [];
  let semesterCalendar = [];
  let weekBar = [];
  let monthBar = [];
  let semesterBar = [];

  let i = dayjs();
  let j = dayjs();

  let week = data.filter(
    (transaction) =>
      dayjs(transaction.dateTransaction) >= dayjs().subtract(7, "days")
  );
  let month = data.filter(
    (transaction) =>
      dayjs(transaction.dateTransaction) >= dayjs().subtract(1, "month")
  );
  let semester = data.filter(
    (transaction) =>
      dayjs(transaction.dateTransaction) >= dayjs().subtract(6, "month")
  );

  while (i > dayjs().subtract(1, "month")) {
    if (i > dayjs().subtract(1, "month")) {
      monthCalendar.push(i.format("YYYY-MM-DD"));
    }
    if (i > dayjs().subtract(7, "days")) {
      weekCalendar.push(i.format("YYYY-MM-DD"));
    }
    i = i.subtract(1, "days");
  }
  weekCalendar.reverse();
  monthCalendar.reverse();

  while (j > dayjs().subtract(6, "month")) {
    if (!semesterCalendar.includes(j.format("MMM"))) {
      semesterCalendar.push(j.format("MMM"));
    }
    j = j.subtract(1, "month");
  }
  semesterCalendar.reverse();

  weekCalendar.forEach((day) => {
    weekBar.push({ dateTransaction: day, amount: 0, amount_color: "#72a7fe" });
  });

  monthCalendar.forEach((day) => {
    monthBar.push({ dateTransaction: day, amount: 0, amount_color: "#72a7fe" });
  });

  semesterCalendar.forEach((month) => {
    semesterBar.push({
      dateTransaction: month,
      amount: 0,
      amount_color: "#72a7fe",
    });
  });

  week.forEach((transaction) => {
    weekBar.forEach((day) => {
      if (
        day.dateTransaction ===
        dayjs(transaction.dateTransaction).format("YYYY-MM-DD")
      ) {
        day.amount = day.amount += transaction.amountCollected;
      }
    });
  });

  month.forEach((transaction) => {
    monthBar.forEach((day) => {
      if (
        day.dateTransaction ===
        dayjs(transaction.dateTransaction).format("YYYY-MM-DD")
      ) {
        day.amount = day.amount += transaction.amountCollected;
      }
    });
  });

  semester.forEach((transaction) => {
    semesterBar.forEach((month) => {
      if (
        month.dateTransaction ===
        dayjs(transaction.dateTransaction).format("MMM")
      ) {
        month.amount = month.amount += transaction.amountCollected;
      }
    });
  });

  callBackWeek(weekBar);
  callBackMonth(monthBar);
  callBackSemester(semesterBar);
};

const lineDataProcessing = (data, callback) => {
  let monthData = [];
  let lineData = [{ id: "amount", color: "#002055" }];
  let i = dayjs();

  let transactions = data.filter(
    (transaction) =>
      transaction.dateTransaction >=
      dayjs().subtract(1, "month").format("YYYY-MM-DD")
  );

  while (i > dayjs().subtract(1, "month")) {
    monthData.push({ x: i.format("YYYY-MM-DD"), y: 0 });
    i = i.subtract(1, "days");
  }
  monthData.reverse();

  transactions.forEach((transaction) => {
    monthData.forEach((day) => {
      if (day.x === dayjs(transaction.dateTransaction).format("YYYY-MM-DD")) {
        day.y += transaction.amountCollected;
      }
    });
  });

  lineData[0].data = monthData;

  callback(lineData);
};

export {
  barDataProcessing,
  pieDataProcessing,
  statsBoxContentProcessing,
  lineDataProcessing,
};
