import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  credentials: "include",
});
export const axiosPrivate = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { "Content-Type": "multipart/form-data" },
  withCredentials: true,
});
