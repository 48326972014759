import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Header from "../../components/Header";
import GeneralSettings from "./general";
import StoreSettings from "./stores";
import UserSettings from "./users";
import { Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import Providers from "./providers";

export default function SettingsTab() {
  const colors = tokens(useTheme().palette.mode);
  const [value, setValue] = React.useState("1");
  const [show, setShow] = React.useState(false);
  const axiosPrivate = useAxiosPrivate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axiosPrivate.get("/users/storeChain");
        setShow(true);
      } catch (err) {}
    };

    getUsers();
  }, []);

  return (
    <Box m="8px">
      <Header
        title="Configurações"
        subtitle="Ajuste as opções da sua conta"
        color="#621B74"
      />
      {show ? (
        <TabContext value={value}>
          <Box
            sx={{
              borderColor: colors.grey[300],
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <TabList
              TabIndicatorProps={{ style: { backgroundColor: "#621B74" } }}
              scrollButtons="auto"
              allowScrollButtonsMobile
              indicatorColor="primary"
              textColor="inherit"
              onChange={handleChange}
              aria-label="Alterne entre opções"
            >
              <Tab label="Lojas" value="1" />
              <Tab label="Provedoras" value="2" />
              <Tab label="Usuários" value="3" />
              <Tab label="Planos" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ py: "4px", px: "0px" }}>
            {<StoreSettings />}
          </TabPanel>
          <TabPanel value="2" sx={{ py: "4px", px: "0px" }}>
            {<Providers />}
          </TabPanel>
          <TabPanel value="3" sx={{ py: "4px", px: "0px" }}>
            {<UserSettings />}
          </TabPanel>
          <TabPanel value="4" sx={{ py: "4px", px: "0px" }}>
            {<GeneralSettings />}
          </TabPanel>
        </TabContext>
      ) : (
        <Box
          sx={{
            borderRadius: 3,
            autoComplete: "off",
            padding: 3,
            gap: 2,
            bgcolor: colors.primary[400],
            display: "grid",
          }}
        >
          <Typography variant="h5" fontWeight="bold">
            Não Autorizado
          </Typography>
        </Box>
      )}
    </Box>
  );
}
