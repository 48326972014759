import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'

const PageNotFound = () => {
  return (
   <>
   <head>
  <link
    href="https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@600;900&display=swap"
    rel="stylesheet"
  />
  <script
    src="https://kit.fontawesome.com/4b9ba14b0f.js"
    crossorigin="anonymous"
  ></script>
</head>
<Box height='100vh'sx={{backgroundColor: '#95c2de'}}
>
  <Box sx={{backgroundColor: '#95c2de',
  margin: 'auto',
  height: '600px',
  width: '600px',
  position: 'relative'}}>

    <Box sx={{color: '#ffffff',
    fontFamily: 'Nunito Sans',
    fontSize: "11rem",
    position: 'absolute',
    left: '20%',
    top: '8%'}}>4</Box>
    <Box
      sx={{position: 'absolute',
      fontSize: '8.5rem',
      left: '42%', 
      top: '15%',
      color: '#ffffff'}}>
<FontAwesomeIcon icon={faCircleQuestion} spin />
</Box>
<Box sx={{color: '#ffffff',
    fontFamily: 'Nunito Sans',
    fontSize: "11rem",
    position: 'absolute',
    left: '68%',
    top: '8%'}}>4</Box>
    <Box sx={{textAlign: 'center',
    fontFamily: 'Nunito Sans',
    fontSize: "1.2rem",
    position: 'absolute',
    left: '16%',
    top: '45%',
    width: '75%'}}>
      <p>The page you are looking for could not be found.</p>
      <p>Maybe this page moved?</p>
      <p>Got deleted?</p>
      <p>Is hiding out in quarantine?</p>
      <p>Never existed in the first place?</p>
      <p>Let's go <a href="/">home</a> and try from there.</p>
    </Box>
  </Box>
</Box>
</>
  )
};

export default PageNotFound;
