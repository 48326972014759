import { Box, Button, useMediaQuery } from "@mui/material";
import {
  GridToolbarQuickFilter,
  DataGrid,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import { tokens } from "../../../theme";
import NewProviderDialog from "../../../components/NewProviderDialog";
import EditProviderDialog from "../../../components/EditProviderDialog";

import EditIcon from "@mui/icons-material/Edit";
import { useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../../api/useAxiosPrivate";
import Toast from "../../../components/Snackbar";
import LinearProgress from "@mui/material/LinearProgress";
import CustomNoRowsOverlay from "../../../components/NoRowsOverlay";
import Chip from "@mui/material/Chip";

const PROVIDERS_URL = "/providers";

const Providers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const axiosPrivate = useAxiosPrivate();

  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

  const [providers, setProviders] = useState([]);
  const [providerInfo, setProviderInfo] = useState({});

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const [loadingData, setLoadingData] = useState(true);
  const [openNewProviderDialog, setOpenNewProviderDialog] = useState(false);
  const [openEditProviderDialog, setOpenEditProviderDialog] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");

  useEffect(() => {
    const getProviders = async () => {
      try {
        const response = await axiosPrivate.get(PROVIDERS_URL);
        setProviders(response.data);
        setLoadingData(false);
      } catch (err) {}
    };

    getProviders();
  }, [openEditProviderDialog, openNewProviderDialog]);

  const newProvider = () => {
    setOpenNewProviderDialog(true);
  };

  const handleClose = () => {
    setOpenNewProviderDialog(false);
    setOpenEditProviderDialog(false);
  };

  const handleEditClick = (id) => () => {
    setProviderInfo(providers.find((provider) => provider.id === id));
    setOpenEditProviderDialog(true);
  };

  const columns = [
    {
      field: "name",
      headerName: "Provedora",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "dailyLimit",
      headerName: "Limite diário (USD)",
      flex: 1,
      valueFormatter: (params) => {
        return USDollar.format(params.value);
      },
    },
    {
      field: "monthlyLimit",
      headerName: "Limite mensal (USD)",
      flex: 1,
      valueFormatter: (params) => {
        return USDollar.format(params.value);
      },
    },
    {
      field: "annualLimit",
      headerName: "Limite anual (USD)",
      flex: 1,
      valueFormatter: (params) => {
        return USDollar.format(params.value);
      },
    },

    {
      field: "providerColor",
      headerName: "Cor",
      flex: 1,

      renderCell: (params) => {
        return (
          <Chip
            sx={{
              backgroundColor: params.row.providerColor,
              color: "white",
              height: "50%",
            }}
          />
        );
      },
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Opções",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 1,
          display: "flex",
          gap: "10px",
        }}
      >
        <GridToolbarQuickFilter
          placeholder="Busque por Nome"
          variant="outlined"
          style={{ flex: 0.5 }}
        />
        <Button
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.primary,
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px",
            border: "10px",
          }}
          id="basic-button"
          onClick={newProvider}
        >
          <AddCircleOutlinedIcon sx={{ mr: "10px" }} />
          Novo
        </Button>
      </Box>
    );
  }

  return (
    <Box
      height="75vh"
      sx={{
        "& .MuiDataGrid-root": {
          border: "none",
        },
        "& .MuiDataGrid-cell": {
          borderBottom: "none",
        },
        "& .name-column--cell": {
          color: "#0c1c3c",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: "#0c1c3c",
          borderBottom: "none",
          color: "#b2bed1",
        },
        "& .MuiDataGrid-virtualScroller": {
          backgroundColor: colors.primary[400],
        },
        "& .MuiDataGrid-footerContainer": {
          borderTop: "none",
          backgroundColor: "#fff",
        },
      }}
    >
      <DataGrid
        loading={loadingData}
        getRowId={(row) => row.id}
        columns={columns}
        rows={providers}
        rowThreshold={0}
        initialState={{ pagination: { paginationModel: { pageSize: 25 } } }}
        pageSizeOptions={[25, 50, 100]}
        slots={{
          toolbar: QuickSearchToolbar,
          loadingOverlay: LinearProgress,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        pagination
        disableColumnMenu
      />

      <Toast
        open={openAlert}
        setOpen={setOpenAlert}
        alertMessage={alertMessage}
        severity={severity}
      />

      <NewProviderDialog
        openNewProviderDialog={openNewProviderDialog}
        handleClose={handleClose}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />

      <EditProviderDialog
        openEditProviderDialog={openEditProviderDialog}
        handleClose={handleClose}
        providerInfo={providerInfo}
        setAlertMessage={setAlertMessage}
        setSeverity={setSeverity}
        setOpenAlert={setOpenAlert}
      />
    </Box>
  );
};

export default Providers;
