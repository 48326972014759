import { ResponsivePie } from "@nivo/pie";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";

const PieChart = (props) => {
  const { data, dolar, CustomLayerComponent } = props;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let USDollar = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 });

  let thousands = new Intl.NumberFormat("en-US", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });

  return (
    <ResponsivePie
      data={data}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[500],
            },
          },
          legend: {
            text: {
              fill: colors.grey[500],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[500],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[500],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[500],
          },
        },
      }}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 40,
          itemWidth: 50,
          itemHeight: 25,
          itemsSpacing: 30,
          symbolSize: 12,
          itemDirection: "top-to-bottom",
          symbolShape: "circle",
        },
      ]}
      margin={{ top: 10, right: 10, bottom: 50, left: 10 }}
      id="providerId"
      colors={{ datum: "data.color" }}
      valueFormat={dolar && ((value) => `${USDollar.format(value)}`)}
      animate={true}
      innerRadius={0.7}
      padAngle={0.7}
      cornerRadius={2}
      activeOuterRadiusOffset={6}
      borderWidth={1}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      arcLabel={(e) =>
        dolar ? USDollar.format(e.value) : thousands.format(e.value)
      }
      enableArcLinkLabels={false}
      arcLabelsSkipAngle={20}
      arcLabelsTextColor={{
        from: "color",
        modifiers: [["darker", 2]],
      }}
      arcLabelsRadiusOffset={0.5}
      layers={[
        "arcs",
        "arcLabels",
        "arcLinkLabels",
        "legends",
        CustomLayerComponent,
      ]}
      tooltip={({ datum: { id, value, color, data } }) => (
        <div
          style={{
            padding: 12,
            background: color,
          }}
        >
          <strong>
            {data.label}
            <br />
            {dolar && `USD ${USDollar.format(value)}`}
            {!dolar && thousands.format(value)}
          </strong>
        </div>
      )}
    />
  );
};

export default PieChart;
