import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import UploadLedger from "../scenes/transactions/uploadLedger";

export default function UploadLedgerDialog(props) {
  const {
    stores,
    storeProviders,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
    handleClose,
    openUploadLedgerDialog,
  } = props;

  return (
    <div>
      <Dialog
        open={openUploadLedgerDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <UploadLedger
            stores={stores}
            storeProviders={storeProviders}
            handleClose={handleClose}
            setAlertMessage={setAlertMessage}
            setSeverity={setSeverity}
            setOpenAlert={setOpenAlert}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
