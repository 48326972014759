import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import EditProvider from "../scenes/admin/providers/editProvider";

export default function EditProviderDialog(props) {
  const {
    openEditProviderDialog,
    handleClose,
    setAlertMessage,
    setSeverity,
    setOpenAlert,
    providerInfo,
  } = props;

  return (
    <div>
      <Dialog
        open={openEditProviderDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <EditProvider
            providerInfo={providerInfo}
            handleClose={handleClose}
            setAlertMessage={setAlertMessage}
            setSeverity={setSeverity}
            setOpenAlert={setOpenAlert}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
