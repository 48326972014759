import { useState } from "react";
import { Box, TextField, useTheme, Button, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import useAxiosPrivate from "../../../api/useAxiosPrivate";
import { styled } from "@mui/material/styles";

const DELETE_USER_URL = "/users/";

const DeleteUser = (props) => {
  const { handleClose, setAlertMessage, setOpenAlert, setSeverity, user_info } =
    props;

  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [name, setName] = useState(user_info.name);
  const [email, setEmail] = useState(user_info.email);
  const [phoneNumber, setPhoneNumber] = useState(user_info.phoneNumber);

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack

    try {
      const response = await axiosPrivate.delete(
        `${DELETE_USER_URL}${user_info.id}`,
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setAlertMessage(`Usuário Removido com Sucesso!`);
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message);
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Houve um erro na deleção do Usuário");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  return (
    <Box>
      <Header
        title="!REMOVER USUÁRIO"
        subtitle={`Remova o acesso de ${user_info.name} ao sistema`}
        button={false}
        color="#621B74"
      />
      <Box
        borderRadius="10px"
        padding="20px"
        backgroundColor={colors.primary[400]}
        display="grid"
        gap="15px"
        gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 4" },
        }}
      >
        <TextField
          id="name"
          fullWidth
          label="Nome"
          variant="outlined"
          value={name}
          sx={{ gridColumn: "span 4" }}
          inputProps={{ readOnly: true }}
        />

        <TextField
          id="phoneNumber"
          label="Telefone"
          variant="outlined"
          value={phoneNumber}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          inputProps={{ readOnly: true }}
        />

        <TextField
          id="email"
          label="E-mail"
          variant="outlined"
          value={email}
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          inputProps={{ readOnly: true }}
        />

        <TextField
          id="password"
          label="Senha"
          variant="outlined"
          type="password"
          value="********"
          sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          inputProps={{ readOnly: true }}
        />

        <Box sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }} />

        <Button
          type="submit"
          color="secondary"
          variant="contained"
          height="100%"
          onClick={handleSubmit}
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
        >
          <Typography fontWeight="bold">Remover Usuário</Typography>
        </Button>
        <ColorButton
          variant="contained"
          height="100%"
          onClick={handleClose}
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
        >
          Cancelar
        </ColorButton>
      </Box>
    </Box>
  );
};

export default DeleteUser;
