import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import EditBankInformation from "../scenes/Recipients/editBankInformation";

export default function EditBankInformationDialog(props) {
  const {
    open,
    recipient,
    handleClose,
    setAlertMessage,
    setSeverity,
    setOpenAlert,
    bankInformationInfo,
  } = props;

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>
          <EditBankInformation
            handleClose={handleClose}
            bankInformationInfo={bankInformationInfo}
            recipient={recipient}
            setAlertMessage={setAlertMessage}
            setSeverity={setSeverity}
            setOpenAlert={setOpenAlert}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
