import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

import {
  Box,
  TextField,
  useTheme,
  Button,
  Typography,
  useMediaQuery,
  Autocomplete,
  Paper,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import useAxiosPrivate from "../../api/useAxiosPrivate";

const NewBankInformation = (props) => {
  const { recipient, handleClose, setAlertMessage, setOpenAlert, setSeverity } =
    props;
  const [bankName, setBankName] = useState("");
  const [bankBranch, setBankBranch] = useState("");
  const [bankAccount, setBankAccount] = useState("");
  const [pixKeyType, setPixKeyType] = useState(null);
  const [pixKey, setPixKey] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const pixKeyTypes = ["CPF", "Celular", "Email", "Chave Aleatória"];
  const [bankInformations, setBankInformations] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const fetchBankInformations = async () => {
      try {
        const response = await axiosPrivate.get(
          `/bankInformation/recipient/${recipient.id}`,
          {
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            withCredentials: true,
          }
        );
        setBankInformations(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchBankInformations();
  }, [reload]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosPrivate.post(
        "/bankInformation",
        JSON.stringify({
          recipientId: recipient.id,
          bankName,
          bankBranch,
          bankAccount,
          pixKeyType,
          pixKey,
        }),
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setReload(!reload);
        setBankName("");
        setBankBranch("");
        setBankAccount("");
        setPixKeyType(null);
        setPixKey("");
        setAlertMessage("Informações Adicionadas");
        setSeverity("success");
        setOpenAlert(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  return (
    <Box>
      <Header
        title={`INFORMAÇÕES BANCÁRIAS`}
        subtitle={`${recipient.name}`}
        button={false}
        color="#E35A01"
      />
      {bankInformations.length > 0 && (
        <Box
          borderRadius="10px"
          padding="10px"
          marginTop="20px"
          marginBottom="20px"
          backgroundColor={colors.primary[400]}
          display="grid"
          gap="5px"
          sx={{
            "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
            gridTemplateColumns: "repeat(4, 1fr)",
          }}
        >
          <Typography
            variant="h4"
            fontWeight="bold"
            sx={{ gridColumn: "span 4" }}
          >
            Informações Bancárias já Cadastradas
          </Typography>
          <TableContainer
            component={Paper}
            sx={{ gridColumn: "span 4", maxHeight: 200 }}
          >
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <b>Banco</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Agência</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Conta</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Tipo de Chave Pix</b>
                  </TableCell>
                  <TableCell align="left">
                    <b>Chave Pix</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bankInformations.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>{row.bankName}</TableCell>
                    <TableCell>{row.bankBranch}</TableCell>
                    <TableCell>{row.bankAccount}</TableCell>
                    <TableCell>{row.pixKeyType}</TableCell>
                    <TableCell>{row.pixKey}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <Box
        borderRadius="10px"
        padding="10px"
        marginTop="20px"
        marginBottom="20px"
        backgroundColor={colors.primary[400]}
        display="grid"
        gap="5px"
        sx={{
          "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
          gridTemplateColumns: "repeat(4, 1fr)",
        }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ gridColumn: "span 4" }}
        >
          Novas Informações Bancárias
        </Typography>
        <TextField
          size="small"
          fullWidth
          value={bankName}
          variant="outlined"
          type="text"
          label="Nome do Banco"
          sx={{
            backgroundColor: colors.grey[900],
            gridColumn: isNonMobile ? "span 2" : "span 4",
          }}
          onChange={(e) => setBankName(e.target.value)}
          //inputProps={{ readOnly: true }}
        />
        <TextField
          size="small"
          fullWidth
          value={bankBranch}
          variant="outlined"
          type="text"
          label="Agência"
          sx={{
            backgroundColor: colors.grey[900],
            gridColumn: isNonMobile ? "span 1" : "span 2",
          }}
          onChange={(e) => setBankBranch(e.target.value)}
        />
        <TextField
          size="small"
          fullWidth
          value={bankAccount}
          variant="outlined"
          type="text"
          label="Conta"
          sx={{
            backgroundColor: colors.grey[900],
            gridColumn: isNonMobile ? "span 1" : "span 2",
          }}
          onChange={(e) => setBankAccount(e.target.value)}
          //inputProps={{ readOnly: true }}
        />
        <Autocomplete
          fullWidth
          size="small"
          disableClearable
          value={pixKeyType}
          variant="outlined"
          id="pix-type"
          options={pixKeyTypes}
          sx={{
            gridColumn: isNonMobile ? "span 1" : "span 4",
            backgroundColor: colors.grey[900],
          }}
          onChange={(_, newValue) => setPixKeyType(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Tipo de Chave Pix" />
          )}
        />
        <TextField
          size="small"
          fullWidth
          value={pixKey}
          variant="outlined"
          type="text"
          label="Chave Pix"
          sx={{
            backgroundColor: colors.grey[900],
            gridColumn: isNonMobile ? "span 3" : "span 4",
          }}
          onChange={(e) => setPixKey(e.target.value)}
        />
        <Box sx={{ gridColumn: "span 4" }} />
      </Box>
      <Box
        borderRadius="10px"
        padding="10px"
        marginTop="20px"
        marginBottom="20px"
        backgroundColor={colors.primary[400]}
        display="grid"
        gap="5px"
        sx={{
          "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
          gridTemplateColumns: "repeat(4, 1fr)",
        }}
      >
        <Box sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }} />
        <Button
          type="submit"
          color="secondary"
          variant="contained"
          height="100%"
          onClick={handleSubmit}
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
        >
          <Typography fontWeight="bold">Adicionar Informações</Typography>
        </Button>
        <ColorButton
          variant="contained"
          height="100%"
          onClick={handleClose}
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
        >
          FECHAR
        </ColorButton>
      </Box>
    </Box>
  );
};

export default NewBankInformation;
