import { useState } from "react";
import { Box, TextField, useTheme, Button, Typography } from "@mui/material";
import { tokens } from "../../../theme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../../components/Header";
import useAxiosPrivate from "../../../api/useAxiosPrivate";
import { styled } from "@mui/material/styles";

const EDIT_STORE_URL = "/stores";

const EditStore = (props) => {
  const {
    store_info,
    handleClose,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
  } = props;

  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [name, setName] = useState(store_info.name);
  const [phoneNumber, setPhoneNumber] = useState(store_info.phoneNumber);
  const [address, setAddress] = useState(store_info.address);
  const [city, setCity] = useState(store_info.city);
  const [state, setState] = useState(store_info.satate);
  const [zipCode, setZipCode] = useState(store_info.zipCode);

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack
    const v1 = name;
    const v2 = phoneNumber;

    if (!v1 || !v2) {
      setAlertMessage("Verfifique os campos obrigatórios");
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    try {
      const response = await axiosPrivate.put(
        EDIT_STORE_URL,
        JSON.stringify({
          id: store_info.id,
          name,
          phoneNumber,
          address,
          city,
          state,
          zipCode,
        }),
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        setAlertMessage(`Loja "${response.data.name}" editada com Sucesso!`);
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message);
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Houve um erro na criação da Loja");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  return (
    <Box>
      <Header
        title="EDITAR LOJA"
        subtitle={`Altere as Informações da Loja - ${store_info.name}`}
        button={false}
        color="#621B74"
      />
      <Box
        borderRadius="10px"
        padding="20px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="15px"
        gridTemplateColumns="repeat(4, 1fr)"
        sx={{
          "& > div": { gridColumns: "span 4" },
        }}
      >
        <TextField
          id="name"
          fullWidth
          label="Nome da Loja"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          sx={{ gridColumn: "span 4", backgroundColor: colors.primary[100] }}
        />

        <TextField
          id="phoneNumber"
          label="Telefone"
          variant="outlined"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 4",
            backgroundColor: colors.primary[100],
          }}
          required
        />

        <TextField
          id="address"
          label="Endereço"
          variant="outlined"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 4",
            backgroundColor: colors.primary[100],
          }}
        />

        <TextField
          id="city"
          label="Cidade"
          variant="outlined"
          value={city}
          onChange={(e) => setCity(e.target.value)}
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 4",
            backgroundColor: colors.primary[100],
          }}
        />

        <TextField
          id="state"
          label="Estado"
          variant="outlined"
          value={state}
          onChange={(e) => setState(e.target.value)}
          sx={{
            gridColumn: isNonMobile ? "span 1" : "span 2",
            backgroundColor: colors.primary[100],
          }}
        />

        <TextField
          id="zipCode"
          label="ZipCode"
          variant="outlined"
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
          sx={{
            gridColumn: isNonMobile ? "span 1" : "span 2",
            backgroundColor: colors.primary[100],
          }}
        />
        <Box
          sx={{
            gridColumn: isNonMobile ? "span 2" : "span 4",
          }}
        />

        <Button
          type="submit"
          disabled={!name || !phoneNumber}
          color="secondary"
          variant="contained"
          height="100%"
          onClick={handleSubmit}
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
        >
          <Typography fontWeight="bold">Alterar Loja</Typography>
        </Button>
        <ColorButton
          variant="contained"
          height="100%"
          onClick={handleClose}
          sx={{ gridColumn: isNonMobile ? "span 1" : "span 2" }}
        >
          Cancelar
        </ColorButton>
      </Box>
    </Box>
  );
};

export default EditStore;
