import { Box, Button, useTheme, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import { tokens } from "../../theme";

import useAxiosPrivate from "../../api/useAxiosPrivate";
import { red } from "@mui/material/colors";

const DeleteTransaction = (props) => {
  const DELETE_TRANSACTION_URL = "/transactions/";
  const axiosPrivate = useAxiosPrivate();

  const {
    handleClose,
    handleCloseEditTransactionDialog,
    transactionId,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
  } = props;

  const isNonMobile = useMediaQuery("(min-width:600px)");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if button enabled with JS hack

    try {
      const response = await axiosPrivate.delete(
        `${DELETE_TRANSACTION_URL}${transactionId}`,
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );
      if (response.status === 204) {
        setAlertMessage(`Remessa removida com sucesso!`);
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
        handleCloseEditTransactionDialog();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Falha no Registro da Remessa");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  return (
    <Box>
      <Header
        title="!REMOVER REMESSA"
        subtitle="Remova a Remessa do Histórico"
        button={false}
        color="#9f1853"
      />
      <Box
        borderRadius="4px"
        padding="8px"
        backgroundColor={colors.grey[100]}
        display="flex"
        justifyContent="flex-start"
        gap="8px"
        sx={{
          "& > div": { gridColumns: "span 4" },
        }}
      >
        Ao remover esta remessa os arquivos carregados manualmente também serão
        exluídos. <br />
        Esta ação não pode ser desfeita. Tem certeza que deseja remover esta
        remessa?
      </Box>
      <Box
        borderRadius="10px"
        padding="10px"
        marginTop="20px"
        marginBottom="20px"
        backgroundColor={colors.grey[100]}
        display="grid"
        gap="5px"
        sx={{
          "& > div": { gridColumns: isNonMobile ? undefined : "span 4" },
          gridTemplateColumns: "repeat(4, 1fr)",
        }}
      >
        <Box sx={{ gridColumn: "span 4" }} />
        <Box sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }} />
        <Button
          sx={{
            backgroundColor: colors.grey[900],
            color: colors.grey[100],
            "&:hover": {
              backgroundColor: colors.grey[800],
              color: "white",
            },
            gridColumn: isNonMobile ? "span 1" : "span 2",
          }}
          variant="contained"
          height="100%"
          onClick={handleSubmit}
        >
          <Typography fontWeight="bold">Remover Remessa</Typography>
        </Button>
        <Button
          sx={{
            backgroundColor: red[700],
            color: colors.grey[100],
            "&:hover": {
              backgroundColor: red[600],
              color: "white",
            },
            gridColumn: isNonMobile ? "span 1" : "span 2",
          }}
          variant="contained"
          height="100%"
          onClick={handleClose}
        >
          <Typography fontWeight="bold">Cancelar</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default DeleteTransaction;
