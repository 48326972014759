import { useState, useEffect } from "react";
import Image from "../register/Design-sem-nome.jpg";
import msb from "../register/logo.png";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import Toast from "../../components/Snackbar";
import Link from "@mui/material/Link";

import axios from "../../api/axios.js";
const CHECK_TOKEN_URL = "/reset-password-link";
const UPDATE_USER_URL = "/users/password/";
const ResetPasswordLink = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  const PASS_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  const [validPass, setValidPass] = useState(false);
  const [validMatch, setValidMatch] = useState(false);

  const [view, setView] = useState(false);
  const [loading, setLoading] = useState(true);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [userId, setUserId] = useState("");

  const { id, token } = useParams();

  useEffect(() => {
    const checkToken = async () => {
      try {
        const response = await axios.post(
          CHECK_TOKEN_URL,
          JSON.stringify({ id, token }),
          {
            headers: { "Content-Type": "application/json" },
            credentials: "include",
            withCredentials: true,
          }
        );
        response.data.id ? setLoading(false) : setLoading(true);
        response.data.id ? setView(true) : setView(false);
        setUserId(response.data.id);
      } catch (err) {}
    };
    checkToken();
  }, []);

  useEffect(() => {
    setValidPass(PASS_REGEX.test(password));
    setValidMatch(password === confirmPassword);
  }, [password, confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack

    if (!validPass || !validMatch) {
      setAlertMessage("Verfifique os campos obrigatórios");
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    try {
      const response = await axios.put(
        `${UPDATE_USER_URL}${userId}`,
        JSON.stringify({
          token,
          password,
        }),
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 204) {
        setAlertMessage(`Senha Alterada com Sucesso`);
        setSeverity("success");
        setOpenAlert(true);
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message);
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Houve um erro na atualização da Senha");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  return (
    <>
      {view ? (
        <>
          <Toast
            open={openAlert}
            setOpen={setOpenAlert}
            alertMessage={alertMessage}
            severity={severity}
          />
          <Grid container component="main" sx={{ height: "100vh" }}>
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                backgroundImage: `url(${Image})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: (t) =>
                  t.palette.mode === "light"
                    ? t.palette.grey[50]
                    : t.palette.grey[900],
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <Grid
              item
              component={Paper}
              elevation={6}
              square
              backgroundColor={colors.secondary}
              width="50vh"
              height="100vh"
              position="absolute"
              right={0}
            >
              <Box
                sx={{
                  my: 8,
                  mx: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {<img src={msb} height="70px" alt="logo" />}

                <Box>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Nova Senha"
                    name="password"
                    autoComplete="off"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="confirm-password"
                    label="Confirmar Nova Senha"
                    name="confirm-password"
                    autoComplete="off"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type="password"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      disabled={!password || !confirmPassword ? true : false}
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={handleSubmit}
                    >
                      Confirmar
                    </Button>
                  </Box>
                  <Grid item>
                    <Link
                      href="/login"
                      variant="body2"
                      color="rgb(12,28,60)"
                      sx={[
                        {
                          "&:visited": {
                            color: "#0c64fb",
                          },
                        },
                      ]}
                    >
                      {"Fazer login com senha"}
                    </Link>
                  </Grid>
                  <p id="pwdnote">
                    <br />
                    Precisa conter: <br />
                    -Letra maiúscula
                    <br />
                    -Letra minúscula
                    <br />
                    -Um número
                    <br />
                    -Um caractere especial:
                    <br />
                    <span aria-label="exclamation mark">!</span>{" "}
                    <span aria-label="at symbol">@</span>{" "}
                    <span aria-label="hashtag">#</span>{" "}
                    <span aria-label="dollar sign">$</span>{" "}
                    <span aria-label="percent">%</span>
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            borderRadius: 3,
            autoComplete: "off",
            padding: 3,
            gap: 2,
            bgcolor: colors.primary[400],
            display: "grid",
          }}
        >
          {" "}
          {loading ? (
            <Typography variant="h5" fontWeight="bold">
              Carregando informações
            </Typography>
          ) : (
            <Typography variant="h5" fontWeight="bold">
              O link não é válido ou já expirou.
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default ResetPasswordLink;
