import { styled } from "@mui/material/styles";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import {
  Box,
  TextField,
  useTheme,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

const DELETE_CLIENT_URL = "/clients/";
const today = dayjs;

const DeleteClient = (props) => {
  const {
    handleCloseDialog,
    handleCloseEditDialog,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
    clientInfo,
  } = props;
  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack

    try {
      const response = await axiosPrivate.delete(
        `${DELETE_CLIENT_URL}${clientInfo.id}`,
        {
          headers: { "Content-Type": "multipart/form-data" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 204) {
        setAlertMessage(t("clients.deleteClient.successAlertMessage"));
        setSeverity("success");
        setOpenAlert(true);
        handleCloseDialog();
        handleCloseEditDialog();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(t("clients.deleteClient.serverFailAlertMessage"));
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(
          err.response?.data.message ||
            t("clients.deleteClient.serverFailAlertMessage")
        );
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage(t("clients.deleteClient.serverFailAlertMessage"));
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  return (
    <Box>
      <Box>
        <Header
          title={t("clients.deleteClient.header.title")}
          subtitle={t("clients.deleteClient.header.subtitle")}
          button={false}
          color="#f04646"
        />
        <Box
          sx={{
            borderRadius: 3,
            autoComplete: "off",
            padding: 3,
            gap: 2,
            bgcolor: colors.grey[100],
            display: "grid",
            gridTemplateColumns: "repeat(8, 1fr)",
          }}
        >
          {isNonMobile && <Box sx={{ gridColumn: "span 4" }} />}

          <Button
            type="submit"
            color="secondary"
            variant="contained"
            height="100%"
            onClick={handleSubmit}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          >
            <Typography fontWeight="bold">
              {t("clients.form.deleteClientButton")}
            </Typography>
          </Button>
          <ColorButton
            variant="contained"
            height="100%"
            onClick={handleCloseDialog}
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 4" }}
          >
            <Typography fontWeight="bold">
              {t("clients.form.cancelButton")}
            </Typography>
          </ColorButton>
        </Box>
      </Box>
    </Box>
  );
};
export default DeleteClient;
