
const CenterValuePieChart = (customProps) => (layerProps) => {

    const { centerX, centerY, dataWithArc } = layerProps;
    let USDollar = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });

    let thousands = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });

    let total = 0;

    dataWithArc.forEach((arc) => {
      total += arc.value;
    });
    return (
      <text
        x={centerX}
        y={centerY}

        textAnchor="middle"
        dominantBaseline="central"
        color="#01368d"
        style={{
          fontSize: "1.25rem",
          fontWeight: "bold",
          color: "#red",
        }}
      >
        {customProps ? USDollar.format(total) : thousands.format(total)}
      </text>
    );
  };

  export default CenterValuePieChart