import { useContext, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { storeContext } from "../context/storeContext";
import { useTheme, Autocomplete, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

const SelectStore = (props) => {
  const { stores } = props;
  const { t } = useTranslation();

  const [storesList, setStoresList] = useContext(storeContext);

  return (
    <Autocomplete
      fullWidth
      sx={{ m: 0 }}
      size="small"
      multiple
      value={storesList}
      options={stores}
      getOptionLabel={(option) => option.name}
      onChange={(event, newValue) => {
        setStoresList(newValue);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={t("components.selectStore.label")}
          placeholder={t("components.selectStore.placeHolder")}
        />
      )}
      renderOption={(props, option) => (
        <MenuItem
          {...props}
          key={option.id}
          value={option}
          sx={{ justifyContent: "space-between" }}
        >
          {option.name}
        </MenuItem>
      )}
    />
  );
};

export default SelectStore;
