import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import NewRecipient from "../scenes/Recipients/newRecipient";

export default function RecipientDialog(props) {
  const {
    openNewRecipientDialog,
    handleClose,
    clients,
    recipients,
    setAlertMessage,
    setSeverity,
    setOpenAlert,
    client_info,
  } = props;

  return (
    <div>
      <Dialog
        open={openNewRecipientDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <NewRecipient
            handleClose={handleClose}
            clients={clients}
            recipients={recipients}
            setAlertMessage={setAlertMessage}
            setSeverity={setSeverity}
            setOpenAlert={setOpenAlert}
            client_info={client_info}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
