import { ResponsiveBar } from "@nivo/bar";
let USDollar = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const BarChart = (props) => {
  const { data, isNonMobile, perProvider } = props;

  let keys = Object.keys(Object.assign({}, ...data));

  keys = keys.filter(
    (el) => el !== "dateTransaction" && el.slice(-5) !== "color"
  );

  let xticks = data.map((transaction) => {
    return transaction.dateTransaction;
  });

  xticks = xticks.filter((date) => {
    return (
      xticks.indexOf(date) % 5 == 0 || xticks.indexOf(date) == xticks.length - 1
    );
  });

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy="dateTransaction"
      margin={{ top: 50, right: perProvider ? 150 : 60, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={({ id, data }) => String(data[`${id}_color`])}
      minValue={0}
      valueFormat={" ^-$,.2f"}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: isNonMobile ? 0 : -35,
        legendPosition: "middle",
        legendOffset: 32,
        tickValues:
          data.length > 10
            ? xticks
            : data.map((transaction) => {
                return transaction.dateTransaction;
              }),
      }}
      axisLeft={{
        tickSize: 2,
        tickPadding: 2,
        tickRotation: 0,
        legendPosition: "middle",
        legendOffset: -40,
        tickValues: 5,
        format: function (value) {
          return `${USDollar.format(value)}`;
        },
      }}
      enableLabel={true}
      enableTotals={data.length > 10 ? false : true}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 5]],
      }}
      label="start"
      legends={
        perProvider
          ? [
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
          : []
      }
      //animate={true}
      role="application"
      tooltip={({ id, formattedValue, color, data }) => (
        <div
          style={{
            padding: 12,
            background: color,
          }}
        >
          {perProvider && (
            <strong>
              {`USD ${formattedValue}`} <br />
              {`${id}`}
              <br />
              {`Data: ${data.dateTransaction}`}
            </strong>
          )}
          {!perProvider && (
            <strong>
              {`USD ${formattedValue}`} <br />
              {`Data: ${data.dateTransaction}`}
            </strong>
          )}
        </div>
      )}
    />
  );
};

export default BarChart;
