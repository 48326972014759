import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enLang from "./locales/en/en.json";
import esLang from "./locales/es/es.json";
import ptLang from "./locales/pt/pt.json";

const resources = {
  en: {
    translation: enLang,
  },
  es: {
    translation: esLang,
  },
  pt: {
    translation: ptLang,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "pt", // if you're using a language detector, do not define the lng option
    fallbackLng: "pt",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
