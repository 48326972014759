import { useState } from "react";
import { styled } from "@mui/material/styles";

import {
  Box,
  TextField,
  useTheme,
  Button,
  Typography,
  InputLabel,
  MenuItem,
  FormControl,
  useMediaQuery,
  InputAdornment,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import useAxiosPrivate from "../../../api/useAxiosPrivate";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";

const EDIT_PROVIDER_URL = "/providers/";

const EditProvider = (props) => {
  const {
    handleClose,
    setAlertMessage,
    setOpenAlert,
    setSeverity,
    providerInfo,
  } = props;
  const axiosPrivate = useAxiosPrivate();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const providerColors = [
    "#8940fa",
    "#33b1ff",
    "#007d78",
    "#ff7fb6",
    "#70dc8c",
    "#d02770",
    "#d4a00a",
    "#d4a00a",
    "#ba4f01",
    "#d5bbfe",
  ];

  const [name, setName] = useState(providerInfo.name);
  const [dailyLimit, setDailyLimit] = useState(providerInfo.dailyLimit || 0);
  const [monthlyLimit, setMonthlyLimit] = useState(
    providerInfo.monthlyLimit || 0
  );
  const [annualLimit, setAnnualLimit] = useState(providerInfo.annualLimit || 0);
  const [color, setColor] = useState(providerInfo.providerColor);

  const handleChangeColor = (event) => {
    setColor(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // if button enabled with JS hack

    if (!name || !color) {
      setAlertMessage("Verfifique os campos obrigatórios");
      setSeverity("warning");
      setOpenAlert(true);
      return;
    }

    try {
      const response = await axiosPrivate.put(
        `${EDIT_PROVIDER_URL}${providerInfo.id}`,
        JSON.stringify({
          name,
          dailyLimit,
          monthlyLimit,
          annualLimit,
          providerColor: color,
        }),
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          withCredentials: true,
        }
      );

      if (response.status === 204) {
        setAlertMessage(`Provedora Alterada!`);
        setSeverity("success");
        setOpenAlert(true);
        handleClose();
      }
    } catch (err) {
      if (!err?.response) {
        setAlertMessage(
          "O servidor não está respondendo. Por favor tente novamente mais tarde."
        );
        setSeverity("error");
        setOpenAlert(true);
      } else if (err.response?.status === 400) {
        setAlertMessage(err.response?.data.message || "Erro");
        setSeverity("error");
        setOpenAlert(true);
      } else {
        setAlertMessage("Falha no Registro da Provedora");
        setSeverity("error");
        setOpenAlert(true);
      }
    }
  };

  const ColorButton = styled(Button)(() => ({
    color: "white",
    backgroundColor: "#f04646",
    "&:hover": {
      backgroundColor: "#A72323",
    },
  }));

  return (
    <Box>
      <Box>
        <Header
          title="EDITAR PROVEDORA"
          subtitle="Edite as informações da Provedora"
          button={false}
        />
        <Box
          sx={{
            borderRadius: 3,
            autoComplete: "off",
            padding: 3,
            gap: 2,
            bgcolor: colors.primary[400],
            display: "grid",
            gridTemplateColumns: "repeat(6, 1)",
          }}
        >
          <Box sx={{ gridColumn: "span 6" }} />
          <TextField
            required
            id="name"
            label="Nome da Provedora"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ gridColumn: isNonMobile ? "span 4" : "span 6" }}
          />
          <FormControl sx={{ gridColumn: isNonMobile ? "span 2" : "span 6" }}>
            <InputLabel id="select-color">Cor</InputLabel>
            <Select
              labelId="select-color"
              id="select-color"
              label="Cor"
              variant="outlined"
              value={color}
              onChange={handleChangeColor}
              required
              size="small"
            >
              {providerColors.map((color) => (
                <MenuItem key={color} value={color}>
                  {
                    <Chip
                      sx={{ backgroundColor: color, color: "white" }}
                      label={color}
                    />
                  }
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <NumericFormat
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            value={dailyLimit}
            customInput={TextField}
            id="daily-limit"
            variant="outlined"
            type="text"
            label="Limite Diário"
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 6" }}
            onValueChange={(e) => {
              setDailyLimit(e.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          <NumericFormat
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            value={monthlyLimit}
            customInput={TextField}
            id="monthly-limit"
            variant="outlined"
            type="text"
            label="Limite Mensal"
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 6" }}
            onValueChange={(e) => {
              setMonthlyLimit(e.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />
          <NumericFormat
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            value={annualLimit}
            customInput={TextField}
            id="annual-limit"
            variant="outlined"
            type="text"
            label="Limite Anual"
            sx={{ gridColumn: isNonMobile ? "span 2" : "span 6" }}
            onValueChange={(e) => {
              setAnnualLimit(e.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">USD </InputAdornment>
              ),
              sx: { "& input": { textAlign: "right" } },
            }}
          />

          <Box sx={{ gridColumn: "span 4" }} />
          <Button
            type="submit"
            disabled={!name || !color}
            color="secondary"
            variant="contained"
            height="100%"
            onClick={handleSubmit}
            sx={{ gridColumn: "span 1" }}
          >
            <Typography fontWeight="bold">Registrar Provedora</Typography>
          </Button>
          <ColorButton
            variant="contained"
            height="100%"
            onClick={handleClose}
            sx={{ gridColumn: "span 1" }}
          >
            {" "}
            <Typography fontWeight="bold">Cancelar</Typography>
          </ColorButton>
        </Box>
      </Box>
    </Box>
  );
};
export default EditProvider;
